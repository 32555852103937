import { Table, InputGroup, Button, Form } from "react-bootstrap";
import { FilterCollapseIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon } from "../Icons";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { paginationOptions, scrollToTop } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";

import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DamageItemModal from "./DamageItemModal";
import UpdateDamageItemModal from "./UpdateDamagedItemModal";
import NoTableItem from "../utils/NoTableItem";
import PlantItemTable from "./PlantItemTable";
import PlantWarehouseDamagesTable from "./PlantWarehouseDamagesTable";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import ModalLoader from "../utils/ModalLoader";

const damageStatusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "InActive",
    value: "InActive",
  },
];

export default function PlantWarehouseDamages() {
  const { backendUrl } = useAuth();

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showUpdateDamageItemModal, setShowUpdateDamageItemModal] = useState(
    false
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [selectedAll, setSelectedAll] = useState(true);

  const queryClient = useQueryClient();

  useScrollTop();

  const [showDamageItemModal, setShowDamageItemModal] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    withItemData: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });
  const [selectedFaultItem, setSelectedFaultItem] = useState(false);
  const [unRejectedItems, setUnRejectedItems] = useState([]);

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/warehouse/plant-damages/${
        wareshouseLocal.whID
      }?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, items: [] }, refetch } = useQuery(
    ["PLANTS_DAMAGES", queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);

  //-----------------------------
  useEffect(() => {
    let _data = data?.items?.filter((p) => p.DamageStatus === "Active");
    _data = _data?.map((d) => {
      const newItem = {
        ...d,
      };

      return newItem;
    });

    setSelectedItems(_data);
    setUnRejectedItems(_data);
    setAllItems((prev) => data.items);
  }, [data]);

  const sendIssueItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/recieve-damages`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, items }) => {
        queryClient.setQueryData(["PLANTS_DAMAGES", queryParams], (oldData) => {
          let _data = items?.filter((p) => p.DamageStatus === "Active");
          _data = _data?.map((d) => {
            const newItem = {
              ...d,
            };

            return newItem;
          });
          setSelectedItems(_data);
          setUnRejectedItems(_data);
          setAllItems((prev) => oldData?.items);
          return oldData;
        });
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Barcode,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const onSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedItems([]);
      setSelectedAll(!selectedAll);
    } else {
      console.log(data.items);
      let _data = data?.items?.filter((p) => p.DamageStatus === "Active");
      _data = _data.map((d) => {
        const newItem = {
          ...d,
        };

        return newItem;
      });

      setSelectedItems((prev) => _data);
      setUnRejectedItems(_data);
      setSelectedAll(!selectedAll);
    }
  };

  const onChangeSelected = (item) => {
    const _selectedItems = [...selectedItems];

    if (_selectedItems.length > 0) {
      const index = _selectedItems.findIndex((d) => d.FaultID === item.FaultID);
      if (index >= 0) {
        _selectedItems.splice(index, 1);
        setSelectedItems(_selectedItems);
      } else {
        setSelectedItems((prev) => [...prev, item]);
      }
    } else {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  const approveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Accept Damage(s)",
        description:
          "Are you sure you want to accept this Damage(s) to your warehouse",
      })
    ) {
      console.log("selectedItems => ", selectedItems);
      sendIssueItemMutation.mutate(selectedItems);
    }
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter Item Code"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <PlantItemTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                            className="p-cursor"
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Status</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={damageStatusOptions}
                        value={damageStatusOptions.find(
                          (el) => el.value === filterParams.DamageStatus
                        )}
                        onChange={({ value }) =>
                          setFilterParams({
                            ...filterParams,
                            DamageStatus: value,
                          })
                        }
                        isSearchable={false}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  All Damages
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="selectedItems"
                      onChange={onSelectAll}
                      checked={
                        selectedItems?.length === unRejectedItems?.length
                      }
                    />
                    <label className="form-check-label" htmlFor="selectedItems">
                      Select All
                    </label>
                  </div>

                  <button
                    className="btn btn-primary mx-3"
                    disabled={
                      selectedItems?.length < 1 ||
                      sendIssueItemMutation.isLoading
                    }
                    onClick={() => approveAll()}
                  >
                    Accept Damages
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      {/* <th /> */}
                      <th>Fault ID</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>QTY</th>

                      <th>Plant</th>
                      <th>Status</th>
                      <th>Reasons for Return</th>
                      <th>Entry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allItems.map((el, index) => (
                      <PlantWarehouseDamagesTable
                        key={index}
                        el={el}
                        index={index}
                        onChangeSelected={onChangeSelected}
                        selectedItems={selectedItems}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>

              {allItems.length < 1 && <NoTableItem queryParams={queryParams} />}

              {allItems.length > 0 && (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {showDamageItemModal && (
          <DamageItemModal
            showDamageItemModal={showDamageItemModal}
            setShowDamageItemModal={setShowDamageItemModal}
            refetch={refetch}
          />
        )}

        {showUpdateDamageItemModal && (
          <UpdateDamageItemModal
            showUpdateDamageItemModal={showUpdateDamageItemModal}
            setShowUpdateDamageItemModal={setShowUpdateDamageItemModal}
            setSelectedFaultItem={setSelectedFaultItem}
            selectedFaultItem={selectedFaultItem}
            refetch={refetch}
          />
        )}

        <ModalLoader show={sendIssueItemMutation.isLoading} />
      </main>
    </section>
  );
}
