import {
  Modal,
  Form,
  Button,
  Row,
  InputGroup,
  Col,
  Spinner,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { LockIcon } from "./Icons";
import NumberCustomInput from "./utils/NumberCustomInput";
import DateTime from "react-datetime";
import { useAuth } from "../hooks/useAuth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { queryActions } from "../utils/reactQueryActions";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";
import ItemSizeDialog from "./ItemSizeDialog";
import currency from "currency.js";
import moment from "moment";
import PurchaseDialog from "./PurchaseDialog";
import { toast } from "react-toastify";
import NewVendorModal from "./NewVendorModal";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  nanoid,
  amountTypes,
  fetchActionsUtil,
  formatNumberSystem,
  waitFor,
} from "../utils/helpers";
import { appSettings, durationOptions } from "../config";
import CachedIcon from "mdi-react/CachedIcon";
import LoginDialog from "./LoginDialog";
import AddItemSizeDialog from "./modals/AddItemSizeModal";
import { useIsAdmin, useUnits } from "../utils/hooks";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CogSyncOutlineIcon from "mdi-react/CogSyncOutlineIcon";
import ConfirmDialog from "./ConfirmDialogue";
import { FilesAttachments } from "../utils/Attachments";
import { addDays, addMonths, addWeeks } from "date-fns";
import BatchSelector from "./utils/BatchSelector";
import { Popover } from "react-tiny-popover";
import { isEmpty } from "lodash";

const usageOptions = [
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "Office Use",
    value: "Office Use",
  },
  {
    label: "Promo",
    value: "Promo",
  },
];

const discountTypes = [
  {
    label: "Per Item",
    value: "Per Item",
  },
  {
    label: "Total Invoice",
    value: "Total Invoice",
  },
];

const itemsStatus = [
  {
    label: "Unprocessed",
    value: "Unprocessed",
  },
  {
    label: "Processed",
    value: "Processed",
  },
];

export default function NewItemModal({
  showCreateNewInventoryModal,
  setShowCreateNewInventoryModal,
  setItemType,
  refetch,
  thenewItem,
}) {
  const { backendUrl, token } = useAuth();
  const isAdmin = useIsAdmin();
  const queryClient = useQueryClient();
  const [showCreateNewVendorModal, setShowCreateNewVendorModal] = useState(
    false
  );
  const [
    showCreateNewFreightVendorModal,
    setShowCreateNewFreightVendorModal,
  ] = useState(false);
  const [showBatchItemsPopover, setShowBatchItemsPopover] = useState(false);

  const [images, setImages] = useState([]);

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const { Units, getUnitLabelByValue } = useUnits();

  const setUp = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/items/set-up?withVendorBalance=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );

    const { storage } = await fetchActionsUtil(
      `${backendUrl}/api/items/storage-location`,
      "GET"
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = data?.vendors
      .filter((el) => el.Vendor_ID)
      .map((el) => ({
        ...el,
        label: el.CompanyName,
        value: el.Vendor_ID,
      }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    data.storage = storage?.map((el) => ({
      value: el.storageID,
      label: el.name,
    }));

    if (!isEmpty(data.systemNumber)) {
      formik.setFieldValue(
        "Bar_Code",
        formatNumberSystem(
          data.systemNumber?.numberPrefix,
          data.systemNumber?.currentNumber
        )
      );
      formik.setFieldValue("numberPrefix", data.systemNumber?.numberPrefix);
      formik.setFieldValue("currentNumber", data.systemNumber?.currentNumber);
    } else {
      formik.setFieldValue("Bar_Code", nanoid(6, "number"));
    }

    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
    },
    refetch: refetchSetup,
    isLoading,
  } = useQuery([queryActions.ITEMS_SETUP], () => setUp(), {
    enabled: true,
    keepPreviousData: false,
  });

  const formik = useFormik({
    initialValues: {
      numberPrefix: "",
      currentNumber: "",
      Vendor: "",
      Item_Type: "Inventory",
      Bar_Code: thenewItem?.Bar_Code ? thenewItem.Bar_Code : "",
      Batch_Name: "",
      Item_Name: thenewItem?.Item_Name ? thenewItem.Item_Name : "",
      InvoiceNo: "",
      Size: "", //`This is saved to Item_Desc
      Unit:
        /*   appSettings.isBatchStandard && !appSettings.hasIncentive
          ? "Each"
          : appSettings.hasIncentive
          ? "Carton"
          : "Tons", */ "",
      Usage: "Sales",
      PurchasePrice: 0,
      Freight: 0,
      UnitCost: 0,
      Quantity: 0,
      ManDate: moment(),
      ExpireDate: moment(),
      Date_Log: moment(),
      Product_Name: "",
      Cat_Name: "",
      Branch: "HQ",
      Reorder_Level: 0,
      Tax: 0,
      Vat_5: 0,
      UnitPrice: 0,
      MaximumLevel: 50,

      //--------
      DriverName: "",
      DriverPhoneNumber: "",
      TruckNumber: "",
      DeliveryDate: moment(),

      // ----
      sizes: [],
      showInStore: false,
      addDiscount: false,

      //---
      Margin: 0,
      Incentive: 0,
      freightVendor: "",
      discountType: "Per Item",
      discount: 0,
      pcsPriceDistributor: 0,

      //-----------------------------------------
      marginType: "Fixed",
      marginPercentage: "",
      incentiveType: "Fixed",
      incentivePercentage: "",
      age: 0,
      ageDurationValue: "Week",
      parentBatchBarCode: "",
      parentBatchItemName: "",
      parentBatchName: "",
      parentBatchUnitCost: 0,
      ItemStatus: "",
      StorageLocation: "",
      markUp: 0,
      markUpType: "Fixed",
      markUpPercentage: "",
      Pack_Size: "",
      Generic_Configuration: "",
      Tier: "",
      Branded_Generic: "",
      itemVAT: 0,
    },
    validationSchema: yup.object().shape({
      Bar_Code: yup.string().required("Item Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      //   InvoiceNo: yup.string().required("Invoice number is required"),
      Product_Name: yup.string().required("required"),
      Cat_Name: yup.string().required("required"),
      // Vendor: yup.string().required(),
      PurchasePrice: yup.string().required(),
      UnitPrice: yup.string().required(),
      Quantity: yup.string().required(),
      Unit: yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (["Tons", "Pieces"].includes(values.Unit) && isEmpty(values.Size)) {
        formik.setFieldError("Size", "Please Select Size");
        return;
      }

      //  Settings
      if (
        Number(values.Quantity) > 0 &&
        Number(values?.PurchasePrice) === 0 &&
        generalSettings?.isPurchasePriceRequired &&
        !isAdmin
      ) {
        return formik.setFieldError(
          "PurchasePrice",
          "Purchase Price is required"
        );
      }

      if (
        Number(values?.PurchasePrice) === 0 &&
        isAdmin &&
        !(await ConfirmDialog({
          title: "Purchase Price Required",
          description: "Purchase price not set, do you want to submit?",
        }))
      ) {
        return;
      }

      if (Number(values.Quantity) > 0 && !values.InvoiceNo) {
        return formik.setFieldError("InvoiceNo", "Invoice number is required");
      }

      if (Number(values.Quantity) > 0 && !values.Vendor) {
        return formik.setFieldError("Vendor", "Vendor is required");
      }

      // Check if vendor balance is enough -----------
      if (Number(values.Quantity) > 0 && appSettings.isGigc) {
        const vendorBalance = await getSelectedVendorBalance(values.Vendor);
        const isNegative = Number(vendorBalance) < 0;
        const notEnoughError =
          "Not enough balance on Vendor ledger to make this purchase";

        if (isNegative) {
          toast.error(notEnoughError);
          if (
            !(await LoginDialog({
              loginRequired: true,
              title: "Admin approval",
              description: notEnoughError,
            }))
          ) {
            return;
          }
        } else {
          const balance = Math.abs(vendorBalance);
          if (Number(balance) < Number(InvoiceAmount)) {
            toast.error(notEnoughError);
            if (
              !(await LoginDialog({
                loginRequired: true,
                title: "Admin approval",
                description: notEnoughError,
              }))
            ) {
              return;
            }
          }
        }
      }
      // --------------------------------------

      values.vendorName = data?.vendors.find(
        (el) => el.value === values.Vendor
      )?.label;
      values.InvoiceAmount = InvoiceAmount;
      values.Total_Freight = Total_Freight;

      let purchase;
      if (Number(values.Quantity) > 0) {
        purchase = await PurchaseDialog({
          item: values,
        });
      }

      values.totalLandingCost = totalLandingCost;
      values.totalDiscount = totalDiscount;

      const formData = new FormData();
      formData.append(
        "payload",
        JSON.stringify({
          newItem: values,
          vendorName: values.vendorName,
          purchaseHistory: purchase,
          isBatchStandard: appSettings.isBatchStandard,
        })
      );

      for (const file of images) {
        formData.append("files", file);
      }

      createMutation.mutate(formData);
    },
    onReset: () => {},
  });

  useEffect(() => {
    if (thenewItem?.Bar_Code) {
      formik.setFieldValue(
        "Bar_Code",
        thenewItem?.Bar_Code ? thenewItem.Bar_Code : ""
      );
    }
    if (thenewItem?.Item_Name) {
      formik.setFieldValue(
        "Item_Name",
        thenewItem?.Item_Name ? thenewItem.Item_Name : ""
      );
    }
  }, [thenewItem]);

  const createItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/add-stock`, {
      method: "POST",
      credentials: "include",
      body: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createMutation = useMutation((payload) => createItem(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      if (refetch) refetch();
      setShowCreateNewInventoryModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const discountPerItem = useMemo(() => {
    return formik.values.discountType === "Per Item"
      ? formik.values.discount
      : 0;
  }, [formik.values.discountType, formik.values.discount]);

  const discountTotalInvoice = useMemo(() => {
    return formik.values.discountType === "Total Invoice"
      ? formik.values.discount
      : 0;
  }, [formik.values.discountType, formik.values.discount]);

  const totalDiscount = useMemo(() => {
    return formik.values.discountType === "Per Item"
      ? currency(formik.values.discount).multiply(formik.values.Quantity).value
      : formik.values.discount;
  }, [formik.values.discountType, formik.values.discount]);

  useEffect(() => {
    if (
      generalSettings.isFarm &&
      formik.values?.parentBatchUnitCost &&
      formik.values?.Quantity
    ) {
      formik.setFieldValue(
        "PurchasePrice",
        currency(formik.values?.parentBatchUnitCost).divide(
          formik.values.Quantity
        ).value
      );
    }
  }, [formik.values.Quantity, formik.values.parentBatchUnitCost]);

  useEffect(() => {
    const UnitCost = currency(formik.values.PurchasePrice, {
      symbol: "",
      separator: "",
    })
      .subtract(discountPerItem)
      .add(formik.values.Freight)
      .format();
    formik.setFieldValue("UnitCost", UnitCost);
  }, [formik.values.PurchasePrice, formik.values.Freight, discountPerItem]);

  //For Incentive
  useEffect(() => {
    if (formik.values.markUpType === "Percentage") {
      formik.setFieldValue(
        "markUp",
        currency(formik.values.markUpPercentage)
          .divide(100)
          .multiply(formik.values.UnitCost).value
      );
    }

    if (formik.values.incentiveType === "Percentage") {
      formik.setFieldValue(
        "Incentive",
        currency(formik.values.incentivePercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }

    if (formik.values.marginType === "Percentage") {
      formik.setFieldValue(
        "Margin",
        currency(formik.values.marginPercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }
  }, [
    formik.values.UnitCost,
    formik.values.marginPercentage,
    formik.values.incentivePercentage,
    formik.values.marginType,
    formik.values.incentiveType,
    formik.values.markUp,
    formik.values.markUpType,
    formik.values.markUpPercentage,
  ]);

  useEffect(() => {
    if (appSettings.hasIncentive) {
      const UnitPrice = currency(formik.values.UnitCost)
        .add(formik.values.Incentive)
        .add(formik.values.Margin).value;

      formik.setFieldValue("UnitPrice", UnitPrice);
      formik.setFieldValue("Tax", UnitPrice);
      formik.setFieldValue("Vat_5", UnitPrice);
    }
  }, [formik.values.UnitCost, formik.values.Margin, formik.values.Incentive]);

  //-----------------------------------
  const InvoiceAmount = useMemo(
    () =>
      currency(formik.values.PurchasePrice, {
        symbol: "",
        separator: "",
      })
        .subtract(discountPerItem)
        .multiply(formik.values.Quantity)
        .subtract(discountTotalInvoice)
        .format(),
    [
      formik.values.PurchasePrice,
      formik.values.Quantity,
      discountPerItem,
      discountTotalInvoice,
    ]
  );

  const Total_Freight = useMemo(
    () =>
      currency(formik.values.Freight, {
        symbol: "",
        separator: "",
      })
        .multiply(formik.values.Quantity)
        .format(),
    [formik.values.Freight, formik.values.Quantity]
  );

  const addItemMeasurement = async () => {
    const newSize = await ItemSizeDialog({});
    if (newSize) {
      queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
        data.itemMeasurement = [
          ...data.itemMeasurement,
          { value: newSize.Size, label: newSize.Size },
        ];
        return data;
      });
      formik.setFieldValue("Size", newSize.Size);

      // add to store
      // console.log(newSize);
      setItemMeasurements([...itemMeasurements, newSize]);
    }
  };

  const setSelectedVendor = (vendor) => {
    if (vendor) {
      queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
        data.vendors = [
          ...data.vendors,
          { label: vendor.CompanyName, value: vendor.Vendor_ID, balance: 0 },
        ];
        return data;
      });
      formik.setFieldValue("Vendor", vendor.Vendor_ID);
    }
  };
  const setSelectedFreightVendor = (vendor) => {
    if (vendor) {
      queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
        data.vendors = [
          ...data.vendors,
          { label: vendor.CompanyName, value: vendor.Vendor_ID, balance: 0 },
        ];
        return data;
      });
      formik.setFieldValue("freightVendor", vendor.Vendor_ID);
    }
  };

  const totalLandingCost = useMemo(() => {
    return currency(formik.values.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.UnitCost, formik.values.Quantity]);

  const getSelectedVendorBalance = (vendor) => {
    const vendorData = data?.vendors?.find(
      (el) => el.value === vendor || el.label === vendor
    );
    return vendorData ? vendorData?.balance : 0;
  };

  const sizeAndColor = async () => {
    if (Number(formik.values.Quantity) === 0) {
      toast.error("Please enter quantity");
      formik.setFieldError("Quantity", "Please enter quantity");
      return;
    }

    const data = await AddItemSizeDialog({
      item: formik.values,
      maxQuantity: formik.values.Quantity,
      previousSizes: formik.values.sizes,
    });

    if (data === false) return;

    formik.setFieldValue(
      "sizes",
      data?.sizesAndColours ? data.sizesAndColours : []
    );
  };

  useEffect(() => {
    const age = formik.values.age;
    const ageDurationValue = formik.values.ageDurationValue;

    const manufacturedDate =
      ageDurationValue === "Day"
        ? addDays(new Date(), -Number(age))
        : ageDurationValue === "Week"
        ? addWeeks(new Date(), -Number(age))
        : ageDurationValue === "Month"
        ? addMonths(new Date(), -Number(age))
        : new Date();

    formik.setFieldValue("ManDate", manufacturedDate);
  }, [formik.values.age, formik.values.ageDurationValue]);

  const handleSelectedBatchItem = (batchItem) => {
    formik.setFieldValue("parentBatchBarCode", batchItem.Bar_Code);
    formik.setFieldValue("parentBatchItemName", batchItem.ItemName);
    formik.setFieldValue("parentBatchName", batchItem.Batch_Name);
    formik.setFieldValue("parentBatchUnitCost", batchItem.UnitCost);
    setShowBatchItemsPopover(false);
  };

  return (
    <Modal
      show={showCreateNewInventoryModal}
      onHide={() => setShowCreateNewInventoryModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>
            Add New Product Information{" "}
            <Button onClick={() => refetchSetup()} variant="">
              <CachedIcon />
            </Button>
          </h1>
          <p>Add a new item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="row new-item-form w-100 m-0"
          autoComplete="off"
        >
          <div className="col-md-6 px-4">
            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-3">Item Type</Form.Label>
              <div className="d-flex gap-3 justify-content-between w-50">
                <Form.Check
                  inline
                  label="Inventory"
                  value="Inventory"
                  name="Item_Type"
                  type="radio"
                  defaultChecked={true}
                />
                <Form.Check
                  inline
                  label="Service"
                  value="Service"
                  name="Item_Type"
                  type="radio"
                  defaultChecked={false}
                  onClick={(e) => setItemType(e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Code</Form.Label>
              <InputGroup>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Item Code"
                  name="Bar_Code"
                  value={formik.values.Bar_Code}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Bar_Code && !!formik.errors.Bar_Code
                  }
                  disabled={isLoading}
                />
                {isLoading && (
                  <InputGroup.Text>
                    <Spinner animation="border" variant="primary" size="sm" />
                  </InputGroup.Text>
                )}

                {/*  <InputGroup.Text
                  title="Generate"
                  onClick={() => formik.setFieldValue("Bar_Code", nanoid(6))}
                >
                  <CogSyncOutlineIcon />
                </InputGroup.Text> */}
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors.Bar_Code}
              </Form.Control.Feedback>
            </Form.Group>

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Amount Rcv</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Amount Rcv"
                  name="username"
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Name"
                name="Item_Name"
                value={formik.values.Item_Name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.Item_Name && !!formik.errors.Item_Name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Item_Name}
              </Form.Control.Feedback>
            </Form.Group>

            {generalSettings.canAddBatchName ? (
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">
                  {generalSettings.Batch_Name}
                </Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder={`Enter ${generalSettings.Batch_Name}`}
                  name="Batch_Name"
                  value={formik.values.Batch_Name}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Batch_Name && !!formik.errors.Batch_Name
                  }
                />
              </Form.Group>
            ) : null}

            {generalSettings.isFarm && (
              <>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Parent Batch</Form.Label>
                  <InputGroup>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Select Parent Batch"
                      name="Bar_Code"
                      value={`${formik.values.parentBatchBarCode ||
                        "..."} / ${formik.values.parentBatchItemName ||
                        "..."} / ${formik.values.parentBatchName || "..."}`}
                      onChange={() => {}}
                    />

                    <Popover
                      isOpen={showBatchItemsPopover}
                      onClickOutside={() => setShowBatchItemsPopover(false)}
                      content={() => (
                        <BatchSelector
                          handleSelectedBatchItem={handleSelectedBatchItem}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() =>
                          setShowBatchItemsPopover(!showBatchItemsPopover)
                        }
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                </Form.Group>

                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">
                    Parent Batch Unit Cost
                  </Form.Label>
                  <CurrencyCustomInput
                    placeholder="0.00"
                    value={formik.values.parentBatchUnitCost}
                    onValueChange={(value, name) => {}}
                    disabled
                  />
                </Form.Group>
              </>
            )}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Manufacturer</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.products}
                value={data?.products?.find(
                  (el) => el.value === formik.values.Product_Name
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Product_Name", value)
                }
              />
              {formik.touched.Product_Name && formik.errors.Product_Name ? (
                <span className="text-danger mt-2">
                  {formik.errors.Product_Name}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Category</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.categories}
                value={data?.categories?.find(
                  (el) => el.value === formik.values.Cat_Name
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Cat_Name", value)
                }
              />
              {formik.touched.Cat_Name && formik.errors.Cat_Name ? (
                <span className="text-danger mt-2">
                  {formik.errors.Cat_Name}
                </span>
              ) : null}
            </Form.Group>

            {generalSettings?.itemStatusSettings && (
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Item Status</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={itemsStatus}
                  value={itemsStatus?.find(
                    (el) => el.value === formik.values.ItemStatus
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("ItemStatus", value)
                  }
                />
                {formik.touched.ItemStatus && formik.errors.ItemStatus ? (
                  <span className="text-danger mt-2">{formik.errors}</span>
                ) : null}
              </Form.Group>
            )}

            <div className="row text-nowrap align-items-center">
              <Form.Group className="col-5 mb-3 pb-2">
                <Form.Label className="mb-1">Unit</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={Units}
                  value={Units.find((el) => el.value === formik.values.Unit)}
                  onChange={({ value }) => {
                    formik.setFieldValue("Unit", value);
                    if (!["Tons", "Pieces"].includes(value)) {
                      formik.setFieldValue("Size", "");
                    }
                  }}
                />
                {formik.touched.Unit && !!formik.errors.Unit ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Unit}
                  </span>
                ) : null}
              </Form.Group>

              {["Tons", "Pieces"].includes(formik.values.Unit) ? (
                <>
                  <Form.Group className="col-5 mb-3 pb-2">
                    <Form.Label className="mb-1">Size</Form.Label>
                    <Select
                      classNamePrefix={`form-select`}
                      options={data?.itemMeasurement}
                      value={data?.itemMeasurement.find(
                        (el) => el.value === formik.values.Size
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("Size", value)
                      }
                    />
                    {formik.touched.Size && !!formik.errors.Size ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Size}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="col-2 pb-2 px-0">
                    <Button size="sm" onClick={() => addItemMeasurement()}>
                      + Add
                    </Button>
                  </Form.Group>
                </>
              ) : (
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Item Description</Form.Label>
                  <Form.Control
                    className=""
                    as="textarea"
                    placeholder="Enter Description"
                    name="Size"
                    value={formik.values.Size}
                    rows={2}
                    onChange={formik.handleChange}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group>
              )}
            </div>

            {appSettings.isMedbury && (
              <div>
                <hr />
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Pack Size</Form.Label>
                  <Form.Control
                    className=""
                    placeholder="Enter Pack Size"
                    name="Pack_Size"
                    value={formik.values.Pack_Size}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">
                    Generic Configuration
                  </Form.Label>
                  <Form.Control
                    className=""
                    placeholder="Enter Generic Configuration"
                    name="Generic_Configuration"
                    value={formik.values.Generic_Configuration}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Tier</Form.Label>
                  <Form.Control
                    className=""
                    placeholder="Enter Tier"
                    name="Tier"
                    value={formik.values.Tier}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Branded/Generic</Form.Label>
                  <Form.Control
                    className=""
                    placeholder="Enter Branded/Generic"
                    name="Branded_Generic"
                    value={formik.values.Branded_Generic}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                <hr />
              </div>
            )}

            <Form.Group className="mb-3 pb-2">
              <Form.Label>
                Quantity ({getUnitLabelByValue(formik.values.Unit)})
              </Form.Label>
              <NumberCustomInput
                placeholder="0"
                name="Quantity"
                value={formik.values.Quantity}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                onBlur={() => formik.setFieldTouched("Quantity", true)}
              />
              {formik.touched.Quantity && !!formik.errors.Quantity ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.Quantity}
                </span>
              ) : null}
            </Form.Group>

            {appSettings.hasSize ? (
              <Form.Group className="mb-3 pb-2">
                <Button size="sm" type="button" onClick={() => sizeAndColor()}>
                  + Size / Color
                </Button>

                <span>
                  {formik.values.sizes.map((el) => (
                    <span>{` ${el.Size_Color} - ${el.Quantity},`}</span>
                  ))}
                </span>
              </Form.Group>
            ) : null}

            <div className="row text-nowrap align-items-center">
              <Form.Group className="col-10 mb-3 pb-2">
                <Form.Label className="mb-1">Vendor</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data.vendors}
                  value={
                    data?.vendors?.find(
                      (el) => el.value === formik.values.Vendor
                    ) || ""
                  }
                  onChange={({ value }) =>
                    formik.setFieldValue("Vendor", value)
                  }
                />
                {formik.touched.Vendor && formik.errors.Vendor ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Vendor}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-2 pb-2 px-0">
                <Button
                  size="sm"
                  onClick={() => setShowCreateNewVendorModal(true)}
                >
                  + Add
                </Button>
              </Form.Group>
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor Balance</Form.Label>
              <CurrencyCustomInput
                placeholder="0.00"
                value={getSelectedVendorBalance(formik.values.Vendor)}
                readOnly
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor Invoice No</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Vendor Invoice No"
                name="InvoiceNo"
                value={formik.values.InvoiceNo}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.InvoiceNo && !!formik.errors.InvoiceNo
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.InvoiceNo}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Usage</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={usageOptions}
                value={usageOptions.find(
                  (el) => el.value === formik.values.Usage
                )}
                onChange={({ value }) => formik.setFieldValue("Usage", value)}
              />
            </Form.Group>
          </div>

          <div className="col-md-6 px-4">
            <div className="row">
              {/*  <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Unit Cost</Form.Label>
                <Select />
              </Form.Group> */}

              <Form.Group className="col-md-6 mb-3 pb-2">
                {/* <Form.Label className="mb-1">Cost Price</Form.Label> */}
                <Form.Label className="mb-1">Unit Cost</Form.Label>
                <CurrencyCustomInput
                  name="PurchasePrice"
                  placeholder="0.00"
                  value={formik.values.PurchasePrice}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.PurchasePrice &&
                    !!formik.errors.PurchasePrice
                  }
                  onBlur={() => formik.setFieldTouched("PurchasePrice", true)}
                />
                {formik.touched.PurchasePrice &&
                !!formik.errors.PurchasePrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.PurchasePrice}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Freight</Form.Label>
                <CurrencyCustomInput
                  name="Freight"
                  placeholder="0.00"
                  value={formik.values.Freight}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Freight && !!formik.errors.Freight}
                  onBlur={() => formik.setFieldTouched("Freight", true)}
                />
                {formik.touched.Freight && !!formik.errors.Freight ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Freight}
                  </span>
                ) : null}
              </Form.Group>

              {/*  <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Discount Type</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={discountTypes}
                  value={discountTypes.find(
                    (el) => el.value === formik.values.discountType
                  )}
                  onChange={(selected) =>
                    formik.setFieldValue("discountType", selected?.value)
                  }
                />
              </Form.Group> */}

              {/*   <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Vendor Discount</Form.Label>
                <CurrencyCustomInput
                  name="discount"
                  placeholder="0.00"
                  value={formik.values.discount}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.discount && !!formik.errors.discount
                  }
                  onBlur={() => formik.setFieldTouched("discount", true)}
                />
                {formik.touched.discount && !!formik.errors.discount ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.discount}
                  </span>
                ) : null}
              </Form.Group> */}

              {generalSettings.hasfreightVendor && (
                <div className="row text-nowrap align-items-center">
                  <Form.Group className="col-10 mb-3 pb-2">
                    <Form.Label className="mb-1">Freight Vendor</Form.Label>
                    <Select
                      classNamePrefix={`form-select`}
                      options={data.vendors}
                      value={
                        data?.vendors?.find(
                          (el) => el.value === formik.values.freightVendor
                        ) || ""
                      }
                      onChange={({ value }) =>
                        formik.setFieldValue("freightVendor", value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-2 pb-2 px-0">
                    <Button
                      size="sm"
                      onClick={() => setShowCreateNewFreightVendorModal(true)}
                    >
                      + Add
                    </Button>
                  </Form.Group>
                </div>
              )}

              <Form.Group className="col-md-6 mb-3 pb-2">
                {/* <Form.Label className="mb-1">Unit Cost</Form.Label> */}
                <Form.Label className="mb-1">Landing Unit Cost</Form.Label>
                <CurrencyCustomInput
                  name="UnitCost"
                  placeholder="0.00"
                  value={formik.values.UnitCost}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitCost && !!formik.errors.UnitCost
                  }
                  onBlur={() => formik.setFieldTouched("UnitCost", true)}
                />
                {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitCost}
                  </span>
                ) : null}
              </Form.Group>

              {appSettings.hasIncentive ? (
                <>
                  <Row>
                    <Form.Group className="col-md-4 mb-3 pb-2">
                      <Form.Label className="mb-1">Mark Up Type</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={amountTypes}
                        value={amountTypes.find(
                          (el) => el.value === formik.values.markUpType
                        )}
                        onChange={(selected) =>
                          formik.setFieldValue("markUpType", selected.value)
                        }
                      />
                    </Form.Group>

                    {formik.values.markUpType === "Percentage" && (
                      <Form.Group className="col-md-3 mb-3 pb-2">
                        <Form.Label className="mb-1">Mark Up (%)</Form.Label>
                        <Form.Control
                          type="number"
                          name="markUpPercentage"
                          value={formik.values.markUpPercentage}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="col-md-5 mb-3 pb-2">
                      <Form.Label className="mb-1">Mark Up</Form.Label>
                      <CurrencyCustomInput
                        name="markUp"
                        placeholder="0.00"
                        value={formik.values.markUp}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        isInvalid={
                          formik.touched.markUp && !!formik.errors.markUp
                        }
                        onBlur={() => formik.setFieldTouched("markUp", true)}
                        disabled={formik.values.markUpType === "Percentage"}
                      />
                      {formik.touched.markUp && !!formik.errors.markUp ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.markUp}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group className="col-md-4 mb-3 pb-2">
                      <Form.Label className="mb-1">Profit Type</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={amountTypes}
                        value={amountTypes.find(
                          (el) => el.value === formik.values.marginType
                        )}
                        onChange={(selected) =>
                          formik.setFieldValue("marginType", selected.value)
                        }
                      />
                    </Form.Group>

                    {formik.values.marginType === "Percentage" && (
                      <Form.Group className="col-md-3 mb-3 pb-2">
                        <Form.Label className="mb-1">Profit (%)</Form.Label>
                        <Form.Control
                          type="number"
                          name="marginPercentage"
                          value={formik.values.marginPercentage}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="col-md-5 mb-3 pb-2">
                      <Form.Label className="mb-1">Profit</Form.Label>
                      <CurrencyCustomInput
                        name="Margin"
                        placeholder="0.00"
                        value={formik.values.Margin}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        isInvalid={
                          formik.touched.Margin && !!formik.errors.Margin
                        }
                        onBlur={() => formik.setFieldTouched("Margin", true)}
                        disabled={formik.values.marginType === "Percentage"}
                      />
                      {formik.touched.Margin && !!formik.errors.Margin ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.Margin}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="col-md-4 mb-3 pb-2">
                      <Form.Label className="mb-1">Incentive Type</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={amountTypes}
                        value={amountTypes.find(
                          (el) => el.value === formik.values.incentiveType
                        )}
                        onChange={(selected) =>
                          formik.setFieldValue("incentiveType", selected.value)
                        }
                      />
                    </Form.Group>

                    {formik.values.incentiveType === "Percentage" && (
                      <Form.Group className="col-md-3 mb-3 pb-2">
                        <Form.Label className="mb-1 text-nowrap">
                          Incentive (%)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="incentivePercentage"
                          value={formik.values.incentivePercentage}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="col-md-5 mb-3 pb-2">
                      <Form.Label className="mb-1">Incentive</Form.Label>
                      <CurrencyCustomInput
                        name="Incentive"
                        placeholder="0.00"
                        value={formik.values.Incentive}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        isInvalid={
                          formik.touched.Incentive && !!formik.errors.Incentive
                        }
                        onBlur={() => formik.setFieldTouched("Incentive", true)}
                        disabled={formik.values.incentiveType === "Percentage"}
                      />
                      {formik.touched.Incentive && !!formik.errors.Incentive ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.Incentive}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Row>
                </>
              ) : null}

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">
                  Unit Price (
                  {getUnitLabelByValue(
                    !["Tons", "Pieces"].includes(formik.values.Unit)
                      ? formik.values.Unit
                      : "Tons"
                  )}
                  - Retail/Walk-In)
                </Form.Label>
                <CurrencyCustomInput
                  name="UnitPrice"
                  placeholder="0.00"
                  value={formik.values.UnitPrice}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                  onBlur={() => formik.setFieldTouched("UnitPrice", true)}
                />
                {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitPrice}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2 d-flex flex-column justify-content-between">
                <Form.Label className="mb-1">
                  Unit Price{" "}
                  {["Tons", "Pieces"].includes(formik.values.Unit)
                    ? "(Pieces)"
                    : "(Wholesale)"}
                </Form.Label>
                <CurrencyCustomInput
                  name="Tax"
                  placeholder="0.00"
                  value={formik.values.Tax}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Tax && !!formik.errors.Tax}
                  onBlur={() => formik.setFieldTouched("Tax", true)}
                />
                {formik.touched.Tax && !!formik.errors.Tax ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Tax}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">
                  Unit Price (
                  {getUnitLabelByValue(
                    !["Tons", "Pieces"].includes(formik.values.Unit)
                      ? formik.values.Unit
                      : "Tons"
                  )}{" "}
                  - Distributor)
                </Form.Label>
                <CurrencyCustomInput
                  name="Vat_5"
                  placeholder="0.00"
                  value={formik.values.Vat_5}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Vat_5 && !!formik.errors.Vat_5}
                  onBlur={() => formik.setFieldTouched("Vat_5", true)}
                />
                {formik.touched.Vat_5 && !!formik.errors.Vat_5 ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Vat_5}
                  </span>
                ) : null}
              </Form.Group>

              {formik.values.Unit === "Tons" && (
                <Form.Group className="col-md-6 mb-3 pb-2 d-flex flex-column justify-content-between">
                  <Form.Label>Pieces Price (Distributor) </Form.Label>
                  <CurrencyCustomInput
                    type="text"
                    name="pcsPriceDistributor"
                    value={formik.values.pcsPriceDistributor}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.pcsPriceDistributor &&
                      !!formik.errors.pcsPriceDistributor
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.pcsPriceDistributor}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label title={totalLandingCost} className="mb-1">
                  Invoice Amount
                </Form.Label>
                <CurrencyCustomInput
                  placeholder="0.00"
                  value={InvoiceAmount}
                  readOnly
                />
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label title={totalLandingCost} className="mb-1">
                  VAT
                </Form.Label>
                <CurrencyCustomInput
                  placeholder="0.00"
                  name="itemVAT"
                  value={formik.values.itemVAT}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
            </div>

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Item Status</Form.Label>
                <Select classNamePrefix={`form-select`} />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}

            {/* <Form.Group className="mb-3 pb-2">
                <Form.Label>QTY in Stock</Form.Label>
                <NumberCustomInput placeholder="0" name="qty" />
              </Form.Group> */}

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">New Vendor</Form.Label>
                <Select classNamePrefix={`form-select`} />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Update Time</Form.Label>
                <Form.Control
                  defaultValue={format(new Date(), "do MM, yyy")}
                  readOnly
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}
            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Mininum Level</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Enter Reorder Level"
                  name="Reorder_Level"
                  value={formik.values.Reorder_Level}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Maximum Level</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Enter Maximum Level"
                  name="MaximumLevel"
                  value={formik.values.MaximumLevel}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
            </div>

            {generalSettings.isFarm && (
              <Form.Group className="mb-3 pb-2">
                <Form.Label>Batch Age</Form.Label>
                <Row>
                  <Col>
                    <NumberCustomInput
                      placeholder="0"
                      name="age"
                      value={formik.values.age}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value, true);
                      }}
                    />
                  </Col>
                  <Col>
                    <Select
                      classNamePrefix={"form-select"}
                      placeholder={""}
                      value={durationOptions.find(
                        (el) => el.value === formik.values.ageDurationValue
                      )}
                      options={durationOptions}
                      onChange={({ value }) =>
                        formik.setFieldValue("ageDurationValue", value)
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            )}

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Branch</Form.Label>
                <Form.Control defaultValue={formik.values.Branch} readOnly />
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Man Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ManDate}
                  onChange={(date) => {
                    formik.setFieldValue("ManDate", date, true);
                  }}
                />
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Expire Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ExpireDate}
                  onChange={(date) => {
                    formik.setFieldValue("ExpireDate", date, true);
                  }}
                />
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Post Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.Date_Log}
                  onChange={(date) => {
                    formik.setFieldValue("Date_Log", date, true);
                  }}
                />
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Check
                  type="switch"
                  label="Show In Online Store"
                  name="showInStore"
                  className="custom-form-check mb-3"
                  checked={formik.values.showInStore}
                  onChange={formik.handleChange}
                />
              </Form.Group>

              <Form.Group className="form-mb align-items-center mb-3">
                <Form.Label>Images</Form.Label>
                <FilesAttachments
                  files={images}
                  setFiles={(files) => setImages(files)}
                />
              </Form.Group>

              <h2 className="mb-3 mt-4 pb-1">Delivery Information</h2>

              <Form.Group className="mb-3 pb-1">
                <Form.Label className="mb-1">Driver</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Driver's Name"
                  name="DriverName"
                  value={formik.values.DriverName}
                  onChange={formik.handleChange}
                  maxLength={255}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label className="mb-1">Driver Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Phone number"
                  name="DriverPhoneNumber"
                  value={formik.values.DriverPhoneNumber}
                  onChange={formik.handleChange}
                />
              </Form.Group>

              <Row>
                <Form.Group className="col mb-3 pb-1">
                  <Form.Label className="mb-1">Truck Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Truck Number"
                    name="TruckNumber"
                    value={formik.values.TruckNumber}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="col mb-3 pb-2">
                  <Form.Label className="mb-1">Date Delivered</Form.Label>
                  <DateTime
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    dateFormat="MMM DD, YYYY"
                    inputProps={{
                      className: `date-input form-control`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.DeliveryDate}
                    onChange={(date) => {
                      formik.setFieldValue("DeliveryDate", date, true);
                    }}
                  />
                </Form.Group>
              </Row>
            </div>
            <div>
              <Form.Group className="col-5 mb-3 pb-2">
                <Form.Label className="mb-1">Storage Location</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data?.storage}
                  value={data?.storage?.find(
                    (el) => el.value === formik.values.StorageLocation
                  )}
                  onChange={({ value }) => {
                    formik.setFieldValue("StorageLocation", value);
                  }}
                />
                {formik.touched.StorageLocation &&
                !!formik.errors.StorageLocation ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.StorageLocation}
                  </span>
                ) : null}
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          onClick={() => formik.submitForm()}
          variant="primary"
          type="submit"
          className="w-50 submit-btn mt-3"
          disabled={createMutation.isLoading}
        >
          {createMutation.isLoading ? "Please wait..." : "Send To Inventory"}
        </Button>
      </Modal.Footer>

      {showCreateNewVendorModal && (
        <NewVendorModal
          showCreateNewVendorModal={showCreateNewVendorModal}
          setShowCreateNewVendorModal={setShowCreateNewVendorModal}
          setSelectedVendor={setSelectedVendor}
        />
      )}
      {showCreateNewFreightVendorModal && (
        <NewVendorModal
          showCreateNewVendorModal={showCreateNewFreightVendorModal}
          setShowCreateNewVendorModal={setShowCreateNewFreightVendorModal}
          setSelectedVendor={setSelectedFreightVendor}
        />
      )}
    </Modal>
  );
}

/* export default function NewItemModal({
  showCreateNewInventoryModal,
  setShowCreateNewInventoryModal,
}) {
  return (
    <Modal
      show={showCreateNewInventoryModal}
      onHide={() => setShowCreateNewInventoryModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add New Item</h1>
          <p>Add a new item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row new-item-form" autoComplete="off">
          <div className="col-md-6">
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Code</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter your username"
                name="username"
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter your username"
                name="username"
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Manufacturer</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Category</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Branch</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Warehouse</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
          </div>

          <div className="col-md-6">
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 invisible">{"..."}</Form.Label>
              <div className="d-flex gap-3 justify-content-between w-50">
                <Form.Check
                  inline
                  label="Inventory"
                  value="Inventory"
                  name="TransType"
                  type="radio"
                />
                <Form.Check
                  inline
                  label="Service"
                  value="Service"
                  name="TransType"
                  type="radio"
                />
              </div>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Storage Location</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Description</Form.Label>
              <Form.Control
                className=""
                as="textarea"
                placeholder=""
                name="username"
                rows={5}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Unit Cost</Form.Label>
              <CurrencyCustomInput placeholder="0.00" />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Unit Price</Form.Label>
              <CurrencyCustomInput placeholder="0.00" />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Status</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label>QTY</Form.Label>

              <div className="duration">
                <NumberCustomInput placeholder="0" name="qty" />

                <Select
                  classNamePrefix={`form-select`}
                  placeholder={"Month"}
                  options={[
                    {
                      value: "Day",
                      label: "Day",
                    },
                    {
                      value: "Week",
                      label: "Week",
                    },
                    {
                      value: "Month",
                      label: "Month",
                    },
                  ]}
                />
              </div>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          variant="primary"
          type="submit"
          className="w-50 submit-btn mt-3"
        >
          Send To Inventory
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
 */
