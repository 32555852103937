import { Modal, Form, Button, InputGroup, Row } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import {
  qtyFormat,
  qtyFormatToString,
  tonsToPcs,
  unitsResolver,
} from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import moment from "moment";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import ItemsTable from "./utils/ItemsTable";
import Datetime from "react-datetime";
import Select from "react-select";
import { useStoreState } from "easy-peasy";
import { isEmpty } from "lodash";
import ConversionDialog from "./ConversionDialog";
import ModalLoader from "./utils/ModalLoader";
import { useAuth } from "../hooks/useAuth";

export const Units = [
  {
    label: "Tons",
    value: "Tons",
  },
  {
    label: "Pieces",
    value: "Pieces",
  },
  {
    label: "Each",
    value: "Each",
  },
  {
    label: "Carton",
    value: "Carton",
  },
  {
    label: "Bags",
    value: "Bags",
  },
  {
    label: "Litres",
    value: "Litres",
  },
  {
    label: "Centiliter",
    value: "Centiliter",
  },
  {
    label: "Drum",
    value: "Drum",
  },
  {
    label: "Bundle",
    value: "Bundle",
  },
  {
    label: "KG",
    value: "kG",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "Roll",
    value: "Roll",
  },
  {
    label: "Packets",
    value: "Packets",
  },
  {
    label: "Cups",
    value: "Cups",
  },
  {
    label: "Rubber",
    value: "Rubber",
  },
  {
    label: "Can",
    value: "Can",
  },
  {
    label: "Sachet",
    value: "Sachet",
  },
  {
    label: "Portion",
    value: "Portion",
  },
  {
    label: "Bottle",
    value: "Bottle",
  },
  {
    label: "Bowl",
    value: "Bowl",
  },
  {
    label: "Tin",
    value: "Tin",
  },
  {
    label: "Pack",
    value: "Pack",
  },
  {
    label: "Ampoules",
    value: "Ampoules",
  },
  {
    label: "Box",
    value: "Box",
  },
  {
    label: "Sachet",
    value: "Sachet",
  },
  {
    label: "Vial",
    value: "Vial",
  },
  {
    label: "Dozen",
    value: "Dozen",
  },
  {
    label: "Crate",
    value: "Crate",
  },
];

export default function QuantityConversionModal({
  showQuantityConversionModal,
  setShowQuantityConversionModal,
  refetch,
  rowToEdit,
  setRowToEdit,
}) {
  const { isIronRod } = useAuth();
  const backendUrl = useBackendUrl();
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showRawItemsPopover, setShowRawItemsPopover] = useState(false);
  const [showFinishedItemsPopover, setShowFinishedItemsPopover] = useState(
    false
  );

  const [rawMaterial, setRawMaterial] = useState();
  const [finishedItem, setFinishedItem] = useState();

  const initialValues = {
    RawMaterialBarcode: "",
    RawMaterialItemName: "",
    FinishedItemBarcode: "",
    FinishedItemName: "",
    RawMaterialQuantity: 0,
    FinishedItemQuantity: 0,
    // Staff_ID: "",
    RawMaterialSize: "",
    FinishedItemSize: "",
    convert: false,
    Date: moment(),
  };
  const schema = yup.object().shape({
    RawMaterialBarcode: yup.string().required("required"),
    FinishedItemBarcode: yup.string().required("required"),
    RawMaterialQuantity: yup
      .number()
      .required("required")
      .moreThan(0, "must be greater than 0"),
    FinishedItemQuantity: yup
      .number()
      .required("required")
      .moreThan(0, "must be greater than 0"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (formValues) => {
      const values = { ...formValues };
      const ogRawMaterialQuantity = values.RawMaterialQuantity;
      const ogFinishedItemQuantity = values.FinishedItemQuantity;

      // --
      if (values.RawMaterialSize === "Tons") {
        values.RawMaterialQuantity = tonsToPcs(
          values.RawMaterialQuantity,
          rawMaterial.Item_Desc,
          itemMeasurements
        );
      }

      if (values.FinishedItemSize === "Tons") {
        values.FinishedItemQuantity = tonsToPcs(
          values.FinishedItemQuantity,
          finishedItem.Item_Desc,
          itemMeasurements
        );
      }

      // --

      if (values.convert) {
        const conversionValues = await ConversionDialog({
          rawMaterial,
          finishedItem,
          ...values,
          RawMaterialQuantity: ogRawMaterialQuantity,
          FinishedItemQuantity: ogFinishedItemQuantity,
          itemMeasurements,
          generalSettings,
          isIronRod,
        });
        if (!conversionValues) return;
        values.conversionValues = conversionValues;
      }
      conversionMutation.mutate({ ...values });
    },
  });

  const setUp = () => {
    if (rowToEdit) {
      //  console.log("(rowToEdit", rowToEdit);
      setRawMaterial(rowToEdit.rawMaterialItemData);
      setFinishedItem(rowToEdit.finishedItemData);
      formik.setValues({
        ...rowToEdit,
        Date: new Date(rowToEdit.Date),
      });
    }
  };

  useEffectOnce(() => {
    setUp();
  });

  const convert = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/save-conversion`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const conversionMutation = useMutation((payload) => convert(payload), {
    onSuccess: ({ message, data }) => {
      refetch();
      toast.success(message);
      setRowToEdit(null);
      setShowQuantityConversionModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handleSelectedRawItem = (item) => {
    if (item.Bar_Code === formik.values.FinishedItemBarcode) {
      return toast.error(
        "This item has already been chosen as finished product"
      );
    }
    formik.setFieldValue("RawMaterialBarcode", item.Bar_Code);
    formik.setFieldValue("RawMaterialItemName", item.Item_Name);
    formik.setFieldValue("RawMaterialSize", item.Product_Model);
    setShowRawItemsPopover(false);
    setRawMaterial(item);
  };

  const handleSelectedFinishedItem = (item) => {
    if (item.Bar_Code === formik.values.RawMaterialBarcode) {
      return toast.error("This item has already been chosen as raw material");
    }
    formik.setFieldValue("FinishedItemBarcode", item.Bar_Code);
    formik.setFieldValue("FinishedItemName", item.Item_Name);
    formik.setFieldValue("FinishedItemSize", item.Product_Model);
    setShowFinishedItemsPopover(false);
    setFinishedItem(item);
  };

  return (
    <>
      <Modal
        show={showQuantityConversionModal}
        onHide={() => {
          setRowToEdit(null);
          setShowQuantityConversionModal(false);
        }}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h6">
              {!isEmpty(rowToEdit) ? "Edit" : ""} Item Conversion
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3 pb-1">
              <Form.Label>Raw Material Barcode</Form.Label>

              <InputGroup>
                <Form.Control
                  name="barcode"
                  value={formik.values.RawMaterialBarcode}
                  onChange={formik.handleChange}
                  placeholder="Input Raw Material Barcode"
                  readOnly
                  isInvalid={
                    formik.touched.RawMaterialBarcode &&
                    !!formik.errors.RawMaterialBarcode
                  }
                />

                {!rowToEdit ? (
                  <Popover
                    isOpen={showRawItemsPopover}
                    onClickOutside={() => setShowRawItemsPopover(false)}
                    content={() => (
                      <ItemsTable
                        ItemStatus="Unprocessed"
                        handleSelectedItem={handleSelectedRawItem}
                      />
                    )}
                    position="bottom"
                  >
                    <InputGroup.Text
                      onClick={() =>
                        setShowRawItemsPopover(!showRawItemsPopover)
                      }
                    >
                      <MagnifyIcon />
                    </InputGroup.Text>
                  </Popover>
                ) : null}
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors.RawMaterialBarcode}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Form.Group className="col mb-3 pb-1">
                <Form.Label>Raw Material Item name</Form.Label>
                <Form.Control
                  value={formik.values.RawMaterialItemName}
                  readOnly
                />
              </Form.Group>
              <Form.Group className="col mb-3 pb-1">
                <Form.Label>Raw Material Quantity in stock</Form.Label>
                <Form.Control
                  value={
                    rawMaterial?.Quantity
                      ? qtyFormatToString(
                          qtyFormat(
                            rawMaterial?.Quantity,
                            rawMaterial?.Item_Desc,
                            itemMeasurements
                          )
                        )
                      : ""
                  }
                  readOnly
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col-8 mb-3 pb-1">
                <Form.Label>Quantity of Raw Material</Form.Label>

                <NumberCustomInput
                  name="RawMaterialQuantity"
                  value={formik.values.RawMaterialQuantity}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.RawMaterialQuantity &&
                    !!formik.errors.RawMaterialQuantity
                  }
                  onBlur={() =>
                    formik.setFieldTouched("RawMaterialQuantity", true)
                  }
                />
                {formik.touched.RawMaterialQuantity &&
                !!formik.errors.RawMaterialQuantity ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.RawMaterialQuantity}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col mb-3 pb-1">
                <Form.Label>Unit</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={unitsResolver(Units, formik.values.RawMaterialSize)}
                  value={Units.find(
                    (el) => el.value === formik.values.RawMaterialSize
                  )}
                  onChange={({ value }) => {
                    formik.setFieldValue("RawMaterialSize", value);
                  }}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>
            </Row>

            <hr className="my-4" />

            <Form.Group className="mb-3 pb-1">
              <Form.Label>Finished Item Barcode</Form.Label>

              <InputGroup>
                <Form.Control
                  name="barcode"
                  value={formik.values.FinishedItemBarcode}
                  placeholder="Input Raw Material Barcode"
                  readOnly
                  isInvalid={
                    formik.touched.FinishedItemBarcode &&
                    !!formik.errors.FinishedItemBarcode
                  }
                />

                {!rowToEdit ? (
                  <Popover
                    isOpen={showFinishedItemsPopover}
                    onClickOutside={() => setShowFinishedItemsPopover(false)}
                    content={() => (
                      <ItemsTable
                        ItemStatus="Processed"
                        handleSelectedItem={handleSelectedFinishedItem}
                      />
                    )}
                    position="bottom"
                  >
                    <InputGroup.Text
                      onClick={() =>
                        setShowFinishedItemsPopover(!showFinishedItemsPopover)
                      }
                    >
                      <MagnifyIcon />
                    </InputGroup.Text>
                  </Popover>
                ) : null}
              </InputGroup>
            </Form.Group>

            <Row>
              <Form.Group className="col mb-3 pb-1">
                <Form.Label>Finished Item Name</Form.Label>
                <Form.Control value={formik.values.FinishedItemName} readOnly />
              </Form.Group>
              <Form.Group className="col mb-3 pb-1">
                <Form.Label>Finished Item Quantity in stock</Form.Label>
                <Form.Control value={finishedItem?.Quantity} readOnly />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col-8 mb-3 pb-1">
                <Form.Label>Quantity of Finished Item</Form.Label>

                <NumberCustomInput
                  type="text"
                  name="FinishedItemQuantity"
                  value={formik.values.FinishedItemQuantity}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.FinishedItemQuantity &&
                    !!formik.errors.FinishedItemQuantity
                  }
                  onBlur={() =>
                    formik.setFieldTouched("FinishedItemQuantity", true)
                  }
                />
                {formik.touched.FinishedItemQuantity &&
                !!formik.errors.FinishedItemQuantity ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.FinishedItemQuantity}
                  </span>
                ) : null}

                <Form.Control.Feedback type="invalid">
                  {formik.errors.FinishedItemQuantity}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col mb-3 pb-1">
                <Form.Label>Unit</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={unitsResolver(Units, formik.values.FinishedItemSize)}
                  value={Units.find(
                    (el) => el.value === formik.values.FinishedItemSize
                  )}
                  onChange={({ value }) => {
                    formik.setFieldValue("FinishedItemSize", value);
                  }}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>
            </Row>

            <hr />

            <Form.Group className="mb-3 pb-1">
              <Form.Label>Date</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                name="Date"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.Date && !!formik.errors.Date
                      ? "is-invalid"
                      : ""
                  }`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.Date}
                onChange={(date) => {
                  formik.setFieldValue("Date", date, true);
                }}
                onBlur={() => formik.setFieldTouched("Date", true)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-3">
          <Button
            disabled={conversionMutation.isLoading}
            variant="outline-primary"
            type="submit"
            className="px-4"
            onClick={() => {
              formik.setFieldValue("convert", false);
              formik.submitForm();
            }}
            name="save"
          >
            Save
          </Button>

          <Button
            disabled={conversionMutation.isLoading}
            variant="primary"
            type="submit"
            onClick={() => {
              formik.setFieldValue("convert", true);
              formik.submitForm();
            }}
            name="convert"
          >
            Save & Convert now
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={conversionMutation.isLoading} />
    </>
  );
}

/* export default function UpdateItemConversionModal(props) {
  return createConfirmation(confirmable(UpdatePrice))({ ...props });
}
 */
