import { Table, Dropdown } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import { useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";

import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog from "../ConfirmDialogue";
import PasswordModal from "../modals/ChangePassword";
import EditUserAccountModal from "../modals/EditUserAccount";
import "../../assets/scss/reports/cashbook.scss";
import { ExchangeFunds } from "../Icons";
import CreateUserAccount from "../modals/CreateUserAccount";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AssignGroupModal from "./AssignCustomerGroupModal";

const UserManagemnt = () => {
  const navigate = useNavigate();
  const { backendUrl } = useAuth();
  const [passwordModal, setPasswordModal] = useState({
    modal: false,
    name: "",
    userId: "",
  });

  const [editUserModal, setEditUserModal] = useState({
    modal: false,
    name: "",
    userId: "",
  });

  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [showAssignGroupModal, setShowAssignGroupModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");

  const { data, refetch } = useQuery(
    ["FETCH STAFF"],
    () => fetchActionsUtil(`${backendUrl}/api/users`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const lockAccount = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/users/status`, "POST", "", payload),
    {
      onSuccess: () => {
        refetch();
        toast.success(`Account ${currentStatus} Successfully`);
      },
      onError: () => {
        toast.error("Unable to lock account");
      },
    }
  );

  const deleteAccount = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/users/${payload}`, "DELETE"),
    {
      onSuccess: () => {
        refetch();
        toast.success("Account deleted Successfully");
      },
      onError: () => {
        toast.error("Unable to delete");
      },
    }
  );

  const onLockAccount = async (Staff_ID, name, status) => {
    const Status = status === "Lock" ? "Open" : "Lock";
    const Distatus = status === "Lock" ? "Unblock" : "block";
    setCurrentStatus(`${Distatus}ed`);
    if (
      await ConfirmDialog({
        title: `${Status} Account`,
        description: `Are you sure you want to ${Distatus.toLocaleUpperCase()} ${name}'s Account`,
      })
    ) {
      lockAccount.mutate({ Staff_ID, Status });
    }
  };

  const onDeleteAccount = async (staffID, name) => {
    if (
      await ConfirmDialog({
        title: "Delete Account",
        description: `Are you sure you want to DELETE ${name}'s Account`,
      })
    ) {
      deleteAccount.mutate(staffID);
    }
  };

  return (
    <div className="dashboard-content">
      <div className=" pt-2 bg-white">
        <main className="table-sticky-header">
          <div className="">
            <header className="container px-4 mt-3 d-flex justify-content-between">
              <h6>
                User List
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h6>
              <div className="actions mr-5 d-flex">
                {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

                {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a> */}
                {/* <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
                <button
                  onClick={() => setOpenCreateUser(true)}
                  className="btn bg print text-black mx-4"
                >
                  Create New <ExchangeFunds color="black" />
                </button>
                <button className="btn btn-primary">
                  <Link className="text-white" to="/audit">
                    Activity Log
                  </Link>
                </button>
              </div>
            </header>

            <div className="px-md-4 my-4">
              <Table striped responsive className="product-table">
                <thead>
                  <tr>
                    <th />
                    <th>Staff ID</th>
                    <th>Title</th>
                    <th>Full Name</th>
                    <th>User Name</th>
                    <th>Department</th>
                    <th>Branch</th>
                    <th>Access Level</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.staff.map((el, i) => (
                    <tr key={i}>
                      <td>
                        <Dropdown alignRight drop="end">
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border-0"
                            bsPrefix="print more"
                          >
                            <DotsVerticalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="div"
                              onClick={() =>
                                setEditUserModal({
                                  userId: el.Staff_ID,
                                  modal: true,
                                  name: el.Name,
                                })
                              }
                              className="p-cursor"
                            >
                              {`Edit ${el.Name} Account`}
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="div"
                              onClick={() =>
                                onDeleteAccount(el.Staff_ID, el.Name)
                              }
                              className="p-cursor"
                            >
                              {`Delete ${el.Name} Account `}
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="div"
                              onClick={() =>
                                setPasswordModal({
                                  userId: el.Staff_ID,
                                  modal: true,
                                  name: el.Name,
                                })
                              }
                              className="p-cursor"
                            >
                              {`Change ${el.Name} Password`}
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="div"
                              onClick={() =>
                                onLockAccount(el.Staff_ID, el.Name, el.Status)
                              }
                              className="p-cursor"
                            >
                              {`${el.Status === "Lock" ? "Unblock" : "Block"} ${
                                el.Name
                              } Account`}
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="div"
                              onClick={() =>
                                navigate(`/audit?&userName=${el.Name}`)
                              }
                              className="p-cursor"
                            >
                              {`${el.Name} Activity Log `}
                            </Dropdown.Item>
                            {el?.Department === "Hospital Management Board" && (
                              <Dropdown.Item
                                as="div"
                                onClick={() => setShowAssignGroupModal(el)}
                                className="p-cursor"
                              >
                                Assign Customer Group
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>{el.Staff_ID}</td>
                      <td>{el.Title}</td>
                      <td>{el.Name}</td>
                      <td>{el.username}</td>
                      <td>{el.Department}</td>
                      <td>{el.Branch}</td>
                      <td>{el.AccessLavel}</td>
                      <td
                        className={`${
                          el.Status === "Lock" ? "text-danger" : "text-success"
                        } fw-bold`}
                      >
                        {el.Status === "Lock" ? "Blocked" : "Active"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </main>
      </div>
      {
        <PasswordModal
          onHide={() => setPasswordModal({ ...passwordModal, modal: false })}
          show={passwordModal.modal}
          modalName={passwordModal.name}
          userId={passwordModal.userId}
        />
      }
      {
        <EditUserAccountModal
          onHide={() => setEditUserModal({ ...editUserModal, modal: false })}
          show={editUserModal.modal}
          modalName={editUserModal.name}
          userId={editUserModal.userId}
          refetch={refetch}
        />
      }
      {
        <CreateUserAccount
          onHide={() => setOpenCreateUser(false)}
          show={openCreateUser}
          refetch={refetch}
        />
      }
      {showAssignGroupModal && (
        <AssignGroupModal
          selectedStaffToAssign={showAssignGroupModal}
          showAssignGroupModal={showAssignGroupModal}
          setShowAssignGroupModal={setShowAssignGroupModal}
        />
      )}
    </div>
  );
};

export default UserManagemnt;
