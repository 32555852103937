import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import {
  copyText,
  customerFullName,
  formatDate,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
  toTonsOrPcs,
  Units,
  waitFor,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  RecieptIcon,
  ChartPieSliceTitleIcon,
  ApproveCheckIcon,
  Checked,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import "./../../assets/scss/requisition/index.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useUpdateEffect,
  useEffectOnce,
  useScrollTop,
  useGetFetchQuery,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import printJS from "print-js";
import { first } from "lodash";
import ProformaDialog from "../ProformaDialog";
import moment from "moment";
import { currenciesOptions } from "../../utils/currencies";
import { truncate } from "lodash";
import { cloneDeep } from "lodash";
import PaystackPop from "@paystack/inline-js";
import ConvertQuantity from "../utils/ConvertQuantity";
import ApprovalBadge from "../utils/ApprovalBadge";
import { map } from "lodash";

const quoteData = [
  { name: "Quote submitted", desc: "Your quote have been submitted" },
  {
    name: "Quote reviewed and approved",
    type: "Status",
    status: "Approved",
    or: "Invoiced",
    desc: `Your quote have been reviewed and approved by company`,
  },
  {
    name: "Quote accepted by you",
    type: "acceptedByCustomer",
    status: true,
    desc: "You have accepted this quote",
  },
  {
    name: "Order being process",
    type: "Status",
    status: "Invoiced",
    desc: "We are getting your order ready",
  },
  {
    name: "Order shipped",
    type: "Shipping_Status",
    status: "Supplied",
    desc: "Your order have been shipped to you and on his way",
  },
  {
    name: "Order delivered",
    type: "isDelivered",
    status: true,
    desc: "You have received your order",
  },
];

const OrderData = [
  { name: "Order submitted", desc: "Your quote have been submitted" },
  {
    name: "Order being process",
    type: "Status",
    status: "Approved",
    or: "Invoiced",
    desc: "We are getting your order ready",
  },
  {
    name: "Order shipped",
    type: "Shipping_Status",
    status: "Supplied",
    desc: "Your order have been shipped to you and on his way",
  },
  {
    name: "Order delivered",
    type: "isDelivered",
    status: true,
    desc: "You have received your order",
  },
];

export default function StoreOrder() {
  const taxOptions = [
    {
      label: "None",
      value: "None",
      percentage: 0,
    },
    {
      label: "VAT",
      value: "VAT",
      percentage: 0.05,
    },
    {
      label: "WHT",
      value: "WHT",
      percentage: 0.05,
    },
    {
      label: "NCD",
      value: "NCD",
      percentage: 0.01,
    },
  ];

  const saleTypes = Units;
  useScrollTop();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const navigate = useNavigate();

  const { customerBackendUrl: backendUrl } = useAuth();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [orderInfo, setOrderInfo] = useState({});
  const [
    showCreateCustomerInfomationModal,
    setShowCreateCustomerInfomationModal,
  ] = useState(false);

  // const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const location = useLocation();

  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  const paystackConfig = {
    //  reference: new Date().getTime().toString(),
    //  email: "user@example.com",
    // amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  };

  const pay = async ({ amount, reference, email }) => {
    try {
      await waitFor(500);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,
        reference,
        email,
        amount: currency(amount).multiply(100).value,
        // other params

        onSuccess: (transaction) => {
          // Payment complete! Reference:
          // console.log(transaction);
          ConfirmDialog({
            title: "Payment Successfull",
            description: `${transaction?.reference}`,
            choice: false,
          });
        },
        onCancel: () => {
          // user closed popup
          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };

  useEffectOnce(() => {
    if (location.state) {
      const { amount, items, orderInfo, pendingTransaction } = location.state;
      if (location.state?.amount) {
        if (
          ![
            "Bank Draft",
            "Cheque",
            "Customer Account",
            "Direct Bank Transfer",
            "Credit",
            "...",
          ].includes(items[0].PayType)
        ) {
          pay({
            amount: location.state?.amount,
            reference: orderInfo.TransactionID,
            email: orderInfo.Email,
          });
        } else {
          ConfirmDialog({
            title: generalSettings?.customerCanEditPriceInCart
              ? "Quotation Sent"
              : "Order Placed",
            description: `Pending Confirmation`,
            choice: false,
          });
        }

        populateTableFromItems(location.state?.items);
        setOrderInfo(location.state?.orderInfo);
      }

      if (pendingTransaction) {
        trackOrderMutation.mutate({
          pendingTransaction,
        });
      }
      navigate(location.pathname, { replace: true });
    }
  });

  const createRodInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodInvoiceMutation = useMutation(
    (payload) => createRodInvoice(payload),
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        /*  if (formik.values.printReciept) {
          window.open(
            `${backendUrl}/api/invoice/pdf/invoice/${data.TransactionID}`,
            "_blank",
            "noopener,noreferrer"
          );
        } */

        formik.resetForm();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  // permit
  const trackOrder = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/permits/detail/${payload.pendingTransaction}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const trackOrderMutation = useMutation((payload) => trackOrder(payload), {
    onSuccess: ({ message, data }) => {
      if (isEmpty(data.permits)) {
        toast.error("Order Not Found");
        formik.resetForm();
        setTableData([]);
        setOrderInfo({});
        return;
      }
      populateTableFromItems(data.permits);
      setOrderInfo(data.orderInfo);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });
  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      PaymentType: "",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: 1,
      pendingTransaction: "",
      supplyNow: true,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      shippingCost: 0,
      terms: [{ text: "" }],
      remark: "Proforma" === "Quotation" ? "" : "",
      otherCharges: 0,
      currency: "NGN",
      linkedPaymentID: "",
      tableDataInForm: [],
      Email: "",
      paymentStatus: "",
    },
    validationSchema: yup.object().shape({
      pendingTransaction: yup.string().required(),
    }),
    onSubmit: async (values) => {
      // send to pending
      trackOrderMutation.mutate({
        ...values,
      });
    },
  });

  const tableData = useMemo(() => {
    // console.log(cloneDeep(formik.values.tableDataInForm));
    return cloneDeep(formik.values.tableDataInForm);
  }, [formik.values?.tableDataInForm]);

  const setTableData = (items) => {
    items = items.map((el) => ({
      ...el,
      //  SubTotal: currency(el.Quantity).multiply(el.UnitPrice).value,
    }));
    formik.setFieldValue("tableDataInForm", [...items]);
  };

  /* useEffectOnce(() => {
    setTableData(cart);
  }); */

  const debouncedTableData = useDebounce(tableData, 1000);
  useEffect(() => {
    // Todo Sync To Server
  }, [debouncedTableData]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      // showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      /*  showSelectBankDialog({
        hasChequeNumber: true,
      }); */
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      /* showSelectBankDialog({
        hasChequeNumber: true,
      }); */
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      /* showSelectBankDialog({
        hasChequeNumber: false,
      }); */
    }
  }, [formik.values.splitPaymentType]);

  const populateTableFromItems = (items) => {
    // convert qtys and clean up

    items = items.map((el) => {
      // check if sales type
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      const tons = value[0];

      return {
        ...el,
        saleType: el.Serial_Number
          ? Number(tons) > 0
            ? "Tons"
            : "Pieces"
          : "",
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
      };
    });

    // console.log(items);

    if (items) {
      formik.resetForm();

      const {
        PayType,
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        terms,
        shippingCost,
        otherCharges,
        remark,
        taxType,
        paymentStatus,
      } = items[0];

      formik.setValues({
        ...formik.values,
        taxValue: VAT,
        PaymentType: PayType,
        OverwriteOfficer,
        salesDate: Date_Log,
        pendingTransaction: TransactionID,
        invoiceCat: ProductName,
        ...(terms ? { terms: JSON.parse(terms) } : {}),
        shippingCost,
        otherCharges,
        remark,
        currency: "NGN",
        taxType: taxType || "None",
        paymentStatus,
      });
      setTableData(items);
      setSelectedCustomer(customer);

      // locked
      setLockedTableData(items);
    }
  };

  const handleAddItem = (item) => {
    // console.log(item);
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }

    // if sale rep version, ensure all other items have same product_name
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      const firstItem = first(tableData);
      console.log(firstItem);
      if (firstItem.Product_Name !== item.Product_Name) {
        return toast.error(
          `Select Items with the same manufacturer (${
            firstItem.Product_Name
          }), `
        );
      }
    }
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );
    if (foundIndex === -1) {
      setTableData([...tableData, item]);
    } else {
      const oldItem = tableData[foundIndex];
      if (
        (oldItem.saleType !== item.saleType && !appSettings.isBatchStandard) ||
        oldItem.Serial_Number !== item.Serial_Number
      ) {
        setTableData([...tableData, item]);
      } else {
        tableData[foundIndex] = item;
        setTableData([...tableData]);
      }
    }

    formik.setFieldValue("invoiceCat", item.Product_Name);
    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }
    const foundIndex = tableData.findIndex(
      (el) =>
        el.Bar_Code === item.Bar_Code &&
        el.saleType === item.saleType &&
        el.Serial_Number === item.Serial_Number
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);
    setEditedItemIndex(null);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer({});
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") setEditedItemIndex(index);
  };

  // discount sum
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
          .map(
            (el) => el.Discount
            /*  currency(el.Discount, { symbol: "", separator: "" })
              .multiply(
                convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
              )
              .format() */
          )
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .add(discount)
          .format()
      : "0.00";
  }, [tableData, discount]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType?.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;

    const total = sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .multiply(100)
          .divide(100)
          .add(taxValue)
          .add(chargesAfterTax)
          .format()
      : "0.00";

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);
    formik.setFieldValue("cashAmount", total);
    return total;
  }, [tableData, taxValue, formik.values.PaymentType, chargesAfterTax]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.Profit)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]);

  const costOFSales = useMemo(() => {
    return tableData
      .map((el) =>
        currency(el.UnitCost, { symbol: "", separator: "" })
          .multiply(convertQuantity(el.Quantity, el.Serial_Number, el.saleType))
          .format()
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    }
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const paymentFilter = useMemo(() => {
    /*   return selectedCustomer?.Cust_ID && selectedCustomer?.Cust_ID !== "000101"
      ? { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" }
      : { Remark: formik.values.ShipTo, Cust_ID: "" }; */
    return { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" };
  }, [selectedCustomer?.Cust_ID /* , formik.values.ShipTo */]);

  const customerPaymentsFilter = useDebounce(paymentFilter, 800);

  const storeSetUpData = useGetFetchQuery("STORE_NAV_SET_UP");

  /*  const fetchPayments = async ({ Cust_ID, Remark }) => {
    let response = await fetch(
      `${backendUrl}/api/customers/get-payments?${queryString.stringify({
        Cust_ID,
        Remark,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.payments = [
      {
        value: "",
        label: "None",
      },
      ...data.payments.map((el) => {
        const requiresApproval = false;
        return {
          ...el,
          value: el.Trans_ID,
          label: `${el.Remark} -  ${currency(el.Credit, {
            symbol: currencySymbol,
          }).format()} - ${el?.Post_Time ? formatDate(el?.Post_Time) : ""} ${
            requiresApproval ? "- Requires Approval" : ""
          }`,
          requiresApproval,
        };
      }),
    ];

    return data;
  }; */

  /*  const { data: paymentsData, ...paymentsDataQuery } = useQuery(
    ["CUSTOMER_PAYMENTS", customerPaymentsFilter],
    () => fetchPayments(customerPaymentsFilter),
    {
      keepPreviousData: false,
      enabled:
        generalSettings?.linkPaymentToInvoice &&
        !!customerPaymentsFilter.Cust_ID ,
    }
  ); */

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------

  const statusIcon = (item, type, status, or) => {
    let iconStatus = <div className="small-circle border normal-icon" />;
    if (type) {
      if (or) {
        if (item[type] === status || item[type] === or) {
          iconStatus = (
            <div className="small-circle border success-icon">
              <Checked size={15} />
            </div>
          );
        }
      } else if (item[type] === status) {
        iconStatus = (
          <div className="small-circle border success-icon">
            <Checked size={15} />
          </div>
        );
      }
    } else {
      iconStatus = (
        <div className="small-circle border success-icon">
          <Checked size={15} />
        </div>
      );
    }

    return iconStatus;
  };

  const displaySide = () => {
    return generalSettings?.customerCanEditPriceInCart ? quoteData : OrderData;
  };
  const disPlayName = (name, ShipMethod) => {
    let reName = name;

    if (ShipMethod && name === "Order shipped") {
      if (ShipMethod === "PickUp") {
        reName = "Order ready for pick up";
      }
    }

    return reName;

    //ShipMethod
  };

  const displayDesc = (desc, name, ShipMethod) => {
    let newDesc = desc;
    if (name === "Quote reviewed and approved") {
      newDesc = `Your quote have been reviewed and approved by ${
        storeSetUpData?.company?.CompName
      }`;
    } else if (ShipMethod && name === "Order shipped") {
      if (ShipMethod === "PickUp") {
        newDesc = "Your order is ready for your pick up";
      }
    }
    return newDesc;
  };

  //console.log(storeSetUpData?.company?.CompName, 1698153446870);
  return (
    <main className="store-main create-invoice">
      <div className="content w-100">
        <FormikProvider value={formik}>
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="d-md-flex content-holder rounded w-100"
          >
            <section className="item-details">
              <div>
                <header>
                  <h1>{`Track Order${
                    generalSettings?.customerCanEditPriceInCart ? "/Quote" : ""
                  }`}</h1>
                </header>
                <div className="row mb-3 align-items-end">
                  <Form.Group className="col-md-5">
                    <Form.Label>
                      Order
                      {generalSettings?.customerCanEditPriceInCart
                        ? "/Quote"
                        : ""}{" "}
                      Number
                    </Form.Label>
                    <Form.Control
                      name="pendingTransaction"
                      value={formik.values.pendingTransaction}
                      onChange={formik.handleChange}
                      placeholder={`Enter Your Order${
                        generalSettings?.customerCanEditPriceInCart
                          ? "/Quote"
                          : ""
                      } Number`}
                    />
                  </Form.Group>

                  {/* <Form.Group className="col-md-4 ">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      placeholder="Email you used during checkout."
                    />
                  </Form.Group> */}

                  <div className="col-md-3">
                    <Button type="submit" className="p-2 px-3">
                      Track
                    </Button>
                  </div>
                </div>

                <div className="selected-data-area">
                  <div className="table-holder">
                    <Table
                      responsive
                      borderless
                      hover
                      striped
                      className="product-table  text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>

                      <tbody>
                        <FieldArray
                          name="tableDataInForm"
                          render={(arrayHelpers) => (
                            <>
                              {formik.values?.tableDataInForm &&
                                formik.values.tableDataInForm.map(
                                  (el, index) => (
                                    <tr
                                      key={index}
                                      // onClick={(e) => handleRowClick(e, index)}
                                      className="p-cursor"
                                    >
                                      <td>{""}</td>
                                      <td>{el.Bar_Code}</td>
                                      <td>
                                        <p className="fw-5">
                                          {el.Item_Name || "..."}
                                        </p>
                                        <span>{truncate(el.Item_Desc)}</span>
                                      </td>
                                      <td>
                                        {currency(el.PriceSold, {
                                          symbol: "₦",
                                        }).format()}{" "}
                                      </td>
                                      <td>
                                        {/*  {currency(el.Quantity, {
                                          symbol: "",
                                          precision: 0,
                                        }).format()}{" "} */}
                                        <ConvertQuantity
                                          quantity={el.Quantity}
                                          desc={el?.Item_Desc}
                                          convertNow={true}
                                        />
                                      </td>
                                      <td>
                                        {currency(el.SubTotal, {
                                          symbol: "₦",
                                        }).format()}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </>
                          )}
                        />
                      </tbody>
                      {formik.values?.shippingCost ? (
                        <tfoot>
                          <tr className="p-cursor">
                            {" "}
                            <td />
                            <td>Shipping Cost</td>
                            <td />
                            <td /> <td />
                            <td>
                              {currency(formik.values.shippingCost, {
                                symbol: "₦",
                              }).format()}
                            </td>
                          </tr>
                        </tfoot>
                      ) : null}
                    </Table>
                  </div>
                  {/*  */}

                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-4">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">...</h2>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end total-info">
                <table className="table table-borderless balance">
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td>
                        {currency(subTotal, {
                          symbol: currencySymbol,
                        })
                          .add(chargesAfterTax)
                          .format()}
                      </td>
                    </tr>

                    {/*  <tr>
                      <td>Discount</td>
                      <td>

                        {currency(discount, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr> */}

                    <tr>
                      <td>Amount Due</td>
                      <td>
                        {currency(amountDue, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>

                    {/*   <tr>
                      <td>Balance</td>
                      <td>
                        {currency(balance, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr> */}

                    <tr>
                      <td>Total</td>
                      <td>
                        {currency(grandTotal, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="customer">
              {tableData.length > 0 && (
                <div className="approval-normal p-3 py-0 mt-3">
                  <div className="approval-history w-100">
                    <div className="item">
                      <div className="timeline" />
                      <div className="detail">
                        <div className="grow-it">
                          <div className="d-flex justify-content-between">
                            <div>
                              <p>
                                <span className="fw-5">Delivery Stage</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p>
                          <span className="fw-5">Status</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* BODY */}
              <div className="approval-normal p-3 py-0 mb-3">
                <div className="approval-history w-100">
                  {tableData.length > 0 &&
                    displaySide().map((el, i) => {
                      return (
                        <div className="item">
                          {i !== 0 && <div className="line" />}
                          <div className="timeline border icon text-primary">
                            <ChartPieSliceTitleIcon />
                          </div>

                          <div className="detail">
                            <div className="grow-it">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p className="mb-1">
                                    <span className="fw-5">
                                      {disPlayName(
                                        el?.name,
                                        tableData[0]?.ShipMethod
                                      )}
                                    </span>
                                  </p>
                                  <span className="text-muted">
                                    {displayDesc(
                                      el.desc,
                                      el?.name,
                                      tableData[0]?.ShipMethod
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            {i !== 0
                              ? statusIcon(
                                  tableData[0],
                                  el.type,
                                  el.status,
                                  el?.or
                                )
                              : statusIcon(tableData[0])}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/* <div className="d-flex justify-content-between">
                <div className="avatar">
                  <UserSolidIcon />
                </div>
                {!isEmpty(orderInfo) ? (
                  <div className="customer-actions d-flex justify-content-between flex-grow-1">
                    <div className="flex-grow-1">
                      <h3>{customerFullName(orderInfo)}</h3>
                     

                      <Table responsive className="">
                        <tbody>
                          <tr>
                            <td>
                              <b>Email:</b>
                            </td>
                            <td>{orderInfo?.Email}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Phone:</b>
                            </td>
                            <td>{orderInfo.PhoneNo1}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Address:</b>
                            </td>
                            <td>{orderInfo.Address}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>State:</b>
                            </td>
                            <td>{orderInfo.state}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>LGA:</b>
                            </td>
                            <td>{orderInfo.LGA}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Payment Status:</b>
                            </td>
                            <td>{formik.values.paymentStatus}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    <div />
                  </div>
                ) : (
                  <div className="customer-actions flex-grow-1">
                    <h3>...</h3>
                    <p>...</p>
                  </div>
                )}
              </div> */}

              {/* <section className="date">
                <h2>Invoice Details</h2>

                <Row className="form-mb">
                  <Form.Group as={Col}>
                    <Form.Label>Date</Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="salesDate"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.salesDate && !!formik.errors.salesDate
                            ? "is-invalid"
                            : ""
                        }`,
                        placeholder: "Select date",
                        readOnly: true,
                        disabled: true,
                      }}
                      value={formik.values.salesDate}
                      onChange={(date) => {
                        formik.setFieldValue("salesDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("salesDate", true)}
                    />
                  </Form.Group>
                </Row>
              </section> */}

              {/* <section className="date">
                <h2>Payment Details</h2>

                <div className="row mb-3">
                  <Form.Group className="col-12">
                    <Form.Label>Payment Type</Form.Label>
                    <p>{formik.values.PaymentType || "..."}</p>
                  </Form.Group>
                </div>

                

                <section>
                  <div className="row">
                    <Form.Group className="col-12 mt-2 form-mb">
                      <Form.Label>Shipping Cost</Form.Label>
                      <p>
                        {currency(formik.values.shippingCost, {
                          symbol: "₦",
                        }).format()}
                      </p>
                    </Form.Group>
                  </div>
                </section>

                
              </section> */}
            </section>
          </Form>
        </FormikProvider>
      </div>

      {/*   Modals */}

      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}

      {showItemSelectorModal && (
        <AddRodItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
          saleTypes={saleTypes}
          lockedTableData={lockedTableData}
          selectedCustomer={selectedCustomer}
        />
      )}

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItem={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
        />
      )}

      <ModalLoader
        show={
          trackOrderMutation.isLoading ||
          createRodInvoiceMutation.isLoading ||
          loadingPrint
        }
      />
    </main>
  );
}
