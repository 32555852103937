import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  copyText,
  customerFullName,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  reactSelectTheme,
  tonsToPcs,
  toTonsOrPcs,
  Units,
} from "./../utils/helpers";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "./Icons";
import NewCustomerModal from "./NewCustomerModal";
import NewItemModal from "./NewItemModal";
import PageHeader from "./PageHeader";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ItemsTable from "./utils/ItemsTable";
import "./../assets/scss/create-invoice.scss";

import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";

import { useMemo } from "react";
import NumberCustomInput from "./utils/NumberCustomInput";
import { services } from "./../config";
import queryString from "query-string";
import { useQuery, useQueryClient } from "react-query";
import { queryActions, reportActions } from "./../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "./SelectBankDialog";
import useDebounce, {
  useEffectOnce,
  useIsAdmin,
  useUpdateEffect,
} from "./../utils/hooks";

import ConfirmDialog from "./ConfirmDialogue";

import { useAuth } from "./../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./utils/ModalLoader";
import printJS from "print-js";
import CustomerSelectModal from "./CustomerSelectModal";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CreatableSelect from "react-select/creatable";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { first } from "lodash";
import { last } from "lodash";
import moment from "moment";
import eventBus from "../utils/EventBus";

const EditableRow = ({ expense, handleRemove, index, edit }) => {
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);
  const initialValues = {
    AccountID: "",
    DetailType: "",
    Type: "",
    Description: "",
    Debit: 0,
    Credit: 0,
    Remark: "",
    bankData: {},
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {},
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = () => {
    formik.setValues({
      AccountID: expense.AccountID,
      DetailType: expense.DetailType,
      Type: expense.Type,
      Description: expense.Description,
      Credit: expense.Credit,
      Debit: expense.Debit,
      Remark: expense?.Remark,
      bankData: expense?.bankData,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  useEffect(() => {
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }
    edit({
      index,
      formValues,
    });
  }, [formValues]);

  const handleSelectedExpense = (expense) => {
    formik.setValues({
      ...formik.values,
      AccountID: expense.AccountID,
      DetailType: expense.DetailType,
      Type: expense.Type,
      Description: expense.Description,
      //Remark: formik.values?.Remark,
    });
    setShowExpensesPopover(false);
  };

  return (
    <tr>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
          >
            <DotsVeritcalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            {/*  <Dropdown.Item as="button" onClick={() => handleRemove(index)}>
              <DeleteIcon />
              Remove
            </Dropdown.Item> */}
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>
      <td>
        <InputGroup className="flex-nowrap">
          <Form.Control
            name="Account"
            value={`${expense.AccountID}/${expense.DetailType || ""}`}
            onChange={() => {}}
            readOnly
          />
          {/*  <Popover
            isOpen={showExpensesPopover}
            onClickOutside={() => setShowExpensesPopover(false)}
            content={() => (
              <ExpensesSelector
                handleSelectedExpense={handleSelectedExpense}
                usage={"chart of accounts"}
              />
            )}
            position="bottom"
          >
            <InputGroup.Text onClick={() => setShowExpensesPopover(true)}>
              <MagnifyIcon />
            </InputGroup.Text>
          </Popover> */}
        </InputGroup>
      </td>
      <td>
        <Form.Control
          name="AccountType"
          value={formik.values.Type}
          onChange={formik.handleChange}
          readOnly
        />
      </td>
      <td>
        <Form.Control
          name="Description"
          value={formik.values.Description}
          onChange={formik.handleChange}
        />
      </td>

      <td>
        <CurrencyCustomInput
          name="Debit"
          value={formik.values.Debit}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
      <td>
        <CurrencyCustomInput
          name="Credit"
          value={formik.values.Credit}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
      <td>
        <Form.Control
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "25rem" }}
        />
      </td>
    </tr>
  );
};

export default function DeleteJournalEntry() {
  let { TransID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isAdmin = useIsAdmin();

  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const { backendUrl, user: authUser } = useAuth();
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);

  const generalSettings = useStoreState((state) => state.generalSettings);
  const [tableData, setTableData] = useState([]);

  useEffectOnce(() => {
    if (location.state && location.state?.expenses) {
      setTableData(location.state.expenses);
      navigate(location.pathname, { replace: true });
    }
  });

  const postJournalEntry = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/journal/journal-entry/edit`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postJournalEntryMutation = useMutation(
    (payload) => postJournalEntry(payload),
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        // formik.resetForm();
        //setTableData([]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );
  const deleteItem = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/journal/delete-entry/${payload.TransID}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteItem(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      //   refetch();
      navigate(-1);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      Reference: "",
      salesDate: moment(),
    },
    validationSchema: yup.object().shape({
      Reference: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Delete Entry",
          description: "Are you sure you want to delete",
        })
      ) {
        deleteMutation.mutate({
          TransID: values.Reference,
        });
      }
    },
    onReset: () => {
      setTableData([]);
    },
  });

  useEffectOnce(() => {
    formik.setFieldValue("Reference", TransID);
  });

  const fetchSetUpData = async (TransID) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/edit-entry/${TransID}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    if (data?.journalEntry) {
      setTableData([
        ...data.journalEntry.map((el) => {
          //  get remark  from  ref
          // const remarkFromRef = el.Ref.split("-");
          return {
            AccountID: el.AccountDescription.AccountID,
            DetailType: el.AccountDescription.DetailType,
            Type: el.AccountID,
            Description: el.Description,
            Credit: el.Credit,
            Debit: el.Debit,
            // Remark: remarkFromRef[remarkFromRef.length - 1],
            Remark: el.Ref,
            bankData: el.bankData,
          };
        }),
      ]);

      formik.setFieldValue(
        "salesDate",
        new Date(data.journalEntry[0].DateLog),
        true
      );
    }
    return data;
  };

  const { data = { journalEntry: [] }, isFetching } = useQuery(
    ["GET_JOURNAL_ENTRY", TransID],
    () => fetchSetUpData(TransID),
    {
      //enabled: false,
    }
  );

  const handleSelectedExpense = (expense) => {
    // balance Table
    if (!isEmpty(tableData)) {
      const lastItem = last(tableData);
      /*  console.log(
        lastItem.Credit,
        Number(lastItem.Credit) > 0,
        lastItem.Debit,
        Number(lastItem.Debit) > 0
      ); */

      expense.Credit = Number(lastItem.Credit) > 0 ? 0 : lastItem.Debit;
      expense.Debit = Number(lastItem.Debit) > 0 ? 0 : lastItem.Credit;
    } else {
      expense.Credit = 0;
      expense.Debit = 0;
    }

    // console.log(expense);

    setTableData([...tableData, expense]);
    setShowExpensesPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedExpense(account);
  });

  const edit = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    }; //clean  up

    setTableData([...tableData]);
  };

  const handleRemove = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    formik.setFieldValue("Reference", TransID);
  };

  return (
    <>
      <main className="create-invoice">
        <PageHeader
          name="Delete Journal Entry"
          description=""
          icon={<CreateInvoiceIcon />}
        />
        <div className="p-3 content">
          <div className="d-md-flex content-holder rounded">
            <section
              /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
              className="item-details"
            >
              <div>
                {/*  <header>
                <h1>Bill Payment</h1>
              </header> */}
                <div className="actions">
                  <div>
                    <h1 className="h4">#{TransID}</h1>
                  </div>
                  <div />
                </div>

                <div className="selected-data-area  ">
                  <div className="table-holder">
                    <Table
                      responsive
                      borderless
                      hover
                      striped
                      className="product-table  text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>GL/Accounts</th>
                          <th>Account Type</th>
                          <th>Description</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody key={tableData?.length}>
                        {tableData.map((el, index) => (
                          <React.Fragment key={index}>
                            <EditableRow
                              index={index}
                              expense={el}
                              handleRemove={handleRemove}
                              edit={edit}
                            />
                          </React.Fragment>
                        ))}
                        <tr style={{ backgroundColor: "#F7f7f7" }}>
                          <td colSpan={7} />
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  {isEmpty(tableData) ? (
                    <div className="no-item my-4">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">Haven't selected an item yet</h2>
                        <p>You can click + Add to add an item to the table.</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/*   <div className="d-flex justify-content-end total-info">
              <table className="table table-borderless balance">
                <tbody>
                  <tr>
                    <td>Total</td>
                    <td>
                      {currency(total, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
            </section>
            <section className="customer">
              <Form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <section className="date">
                  <h2 className="mb-4">Payment Details</h2>

                  <Form.Group className="form-mb align-items-center">
                    <Form.Label>Date</Form.Label>

                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="salesDate"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.salesDate && !!formik.errors.salesDate
                            ? "is-invalid"
                            : ""
                        }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.salesDate}
                      onChange={(date) => {
                        formik.setFieldValue("salesDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("salesDate", true)}
                    />
                  </Form.Group>
                </section>

                <Form.Group className="form-mb align-items-center">
                  <Form.Label>Branch</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    menuPlacement="top"
                    placeholder="HQ"
                    isSearchable={false}
                  />
                </Form.Group>

                <Form.Group className="form-mb align-items-center">
                  <Form.Label>Reference</Form.Label>
                  <Form.Control
                    name="Reference"
                    value={formik.values.Reference}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.Reference && !!formik.errors.Reference
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Reference}
                  </Form.Control.Feedback>
                </Form.Group>

                <section className="buttons">
                  <Button
                    type="button"
                    variant="outline-primary"
                    // className="border-0"
                    onClick={() => discard()}
                    disabled
                  >
                    Refresh
                  </Button>
                  <Button type="submit" variant="primary">
                    Confirm Delete
                  </Button>
                </section>
              </Form>
            </section>
          </div>
        </div>

        {/*   Modals */}

        {/*  {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}


      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItem={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
        />
      )}

      {showPermitModal && (
        <PermitModal
          setShowPermitModal={setShowPermitModal}
          handleSelectedPermit={handleSelectedPermit}
          batchData={true}
        />
      )}

    */}
        <ModalLoader show={postJournalEntryMutation.isLoading || isFetching} />
      </main>
      <ModalLoader show={deleteMutation.isLoading} />
    </>
  );
}
