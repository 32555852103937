import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import { ApproveModalIcon, BookIcon, RecieptIcon } from "./Icons";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { useBackendUrl, useCustomerBackendUrl } from "../utils/hooks";

function Dialog({
  proceed,
  show,
  title = "",
  description = "",
  TransactionID,
  pendingInvoiceType,
  usage,
}) {
  const backendUrl = useCustomerBackendUrl();
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info">
          <ApproveModalIcon className="mb-4" />
          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <section className="row flex-wrap gap-4 mx-3 mt-4">
          {pendingInvoiceType === "Proforma Invoice" ? (
            <Dropdown className="w-100">
              <Dropdown.Toggle variant="primary" className="w-100 py-3">
                Open {pendingInvoiceType}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-with-icons">
                <Dropdown.Item
                  href={`${backendUrl}/api/invoice/pdf/proforma-invoice/${TransactionID}?thermalPrinter=false&cartProforma=${Boolean(
                    usage === "Cart"
                  )}`}
                  target="blank"
                >
                  <RecieptIcon />
                  A4 Size Printer
                </Dropdown.Item>
                <Dropdown.Item
                  href={`${backendUrl}/api/invoice/pdf/proforma-invoice/${TransactionID}?thermalPrinter=true&cartProforma=${Boolean(
                    usage === "Cart"
                  )}`}
                  target="blank"
                >
                  <RecieptIcon />
                  Thermal Printer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <a
              href={
                pendingInvoiceType === "Proforma Invoice"
                  ? `${backendUrl}/api/invoice/pdf/proforma-invoice/${TransactionID}`
                  : `${backendUrl}/api/invoice/pdf/quotation/${TransactionID}?thermalPrinter=false`
              }
              target="blank"
              className="btn btn-primary p-3 col-12"
              //  disabled={isEmpty(TransactionID)}
            >
              Open {pendingInvoiceType}
            </a>
          )}
        </section>
      </Modal.Body>
    </Modal>
  );
}

export default function ProformaDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
