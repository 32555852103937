import { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { services } from "../../config";
import { paginationOptions } from "../../utils/helpers";
import queryString from "query-string";
import currency from "currency.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import CachedIcon from "mdi-react/CachedIcon";
import { truncate } from "lodash";

export default function CustomerBalance() {
  const { backendUrl } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState({
    offset: 0,
    limit: 4,
  });

  const getCustomerBalances = async (page) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/dashboard/customer-balances?by=month&${queryString.stringify(
        page
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    error,
    data = { count: 0 },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(
    ["GET_CUSTOMER_BALANCES", page],
    () => getCustomerBalances(page),
    {
      keepPreviousData: true,
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  const handlePageChange = ({ selected }) => {
    setPage({
      ...page,
      offset: selected,
    });
  };

  return (
    <div className="info">
      <header className="section-header p-3 pe-4">
        <h2>
          Customers Balance{" "}
          <button
            title="Refresh"
            onClick={() => refetch()}
            className="btn text-primary"
          >
            <CachedIcon />
          </button>
        </h2>

        {/*  <Form.Select aria-label="Default select example">
          <option value="1">This Month</option>
          <option value="1">This Year</option>
        </Form.Select> */}
      </header>

      <div className="">
        <div className="table-holder">
          <Table
            responsive
            borderless
            hover
            className="ga-table table-fixed text-nowrap"
          >
            <thead>
              <tr>
                <th>S/N</th>
                <th>Cust ID</th>
                <th>Business Name</th>
                <th>Phone Number</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody className="blue-hover">
              {isSuccess &&
                data.debtors.map((el, i) => (
                  <tr
                    className="p-cursor"
                    onClick={() =>
                      navigate(
                        `/reports/customer-ledger?customerId=${
                          el.Cust_ID
                        }&customerName=${el.Customer}&enableDateRange=false`
                      )
                    }
                    key={i}
                  >
                    <td>{page.offset * page.limit + i + 1}</td>
                    <td>{el.Cust_ID}</td>
                    <td title={el.Customer}>{truncate(el.Customer)}</td>
                    <td>{el.PhoneNo1}</td>
                    <td>
                      {currency(el.TotalDebt, {
                        symbol: "",
                        precision: 2,
                      }).format()}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center flex-wrap gap-3">
          <p className="m-0 d-inline">
            Showing {page.offset * page.limit + 1} to{" "}
            {page.offset * page.limit + 1 + 3} of {data.count} entries
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={data.count / page.limit}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={handlePageChange}
            forcePage={page.offset}
          />
        </div>
      </div>
    </div>
  );
}
