import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import {
  ApproveBlueModalIcon,
  ApproveCheckIcon,
  RejectModalIcon,
} from "./Icons";
import { Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";

function Dialog({
  proceed,
  type = "",
  show,
  title = "",
  description = "",
  items = [],
}) {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <Table striped className="mt-3">
          <thead>
            <tr className="text-nowrap">
              <th>Item</th>
              <th>Qty in Stock</th>
              <th>Qty Locked</th>
              <th>Qty Avaliable to Sell</th>
              <th>Qty to Sell</th>
            </tr>
          </thead>
          <tbody>
            {items.map((el, index) => (
              <tr key={index}>
                <td>
                  {el.Bar_Code} - {el.Item_Name}
                </td>
                <td>
                  {currency(el.quantityInStock, {
                    symbol: "",
                    precision: 1,
                  }).format()}
                </td>
                <td>
                  {currency(el.totalLockedItem, {
                    symbol: "",
                    precision: 1,
                  }).format()}
                </td>
                <td>
                  {currency(el.quantityInStock, {
                    symbol: "",
                    precision: 1,
                    negativePattern: "0",
                  })
                    .subtract(el.totalLockedItem)
                    .format()}
                </td>
                <td>
                  {currency(el.quantityToSell, {
                    symbol: "",
                    precision: 1,
                  }).format()}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          <Button
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Request Unlock
          </Button>
          <Button
            onClick={() => proceed(true)}
            variant="primary"
            autoFocus={true}
          >
            Send for Approval
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function LockedStockDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
