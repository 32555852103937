import { Navigate, NavLink, Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { Tabs, Tab, Form, Table, Pagination, Button } from "react-bootstrap";
import Nav from "./Dashboard/Nav";
import { GearIcon, SideBarToggleIcon } from "./Icons";
import { useMemo, useState } from "react";
import eventBus from "../utils/EventBus";
import {
  useIsAdmin,
  useIsCashier,
  useIsHMB,
  useIsSales,
  useIsStore,
  useIsSupplyAndLogistics,
} from "../utils/hooks";
import { UnAuthorized } from "./utils/UnAuthorized";
import { appSettings } from "../config";

export default function DashboardLayout() {
  const isAdmin = useIsAdmin();
  const isSales = useIsSales();
  const isStore = useIsStore();
  const isCashier = useIsCashier();
  const isHMB = useIsHMB();
  const isSupplyAndLogistics = useIsSupplyAndLogistics();

  const items = useMemo(
    () => [
      {
        name: "Transaction Summary",
        to: "",
      },
      {
        name: "Accounts Dashboard",
        to: "accounts-dashboard",
      },
      ...(isAdmin
        ? [
            {
              name: "System Admin Tool",
              to: "user-managemnt",
            },
            ...(appSettings.isMertesacker
              ? [
                  {
                    name: "Employees",
                    to: "employees",
                  },
                ]
              : []),
          ]
        : []),
    ],
    [isCashier, isAdmin]
  );

  const openSideBar = () => {
    eventBus.dispatch("setIsOpenMobile", true);
  };

  return (
    <>
      {isAdmin || isCashier ? (
        <>
          <main className="master-main">
            <SideBar />
            <Button
              onClick={() => openSideBar()}
              className="side-bar-toggle-btn"
            >
              <SideBarToggleIcon />
            </Button>

            <div className="outlet">
              <Nav />
              <div className="dashboard-tabs px-4">
                {/*<Tabs
          defaultActiveKey="1"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            tabClassName="ga-nav-tab"
            eventKey="1"
            title="Transaction Summary"
          />
          <Tab
            tabClassName="ga-nav-tab"
            eventKey="2"
            title="Accounts Dashboard"
          />
          <Tab
            tabClassName="ga-nav-tab"
            eventKey="3"
            title="System Admin Tool"
          />
</Tabs> */}

                <ul className="nav nav-tabs">
                  {items.map((item, index) => (
                    <li key={index} className="nav-item">
                      <NavLink
                        className={`nav-link ga-nav-tab`}
                        to={item.to}
                        end
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>

                <button className="btn text-nowrap d-none d-md-inline">
                  Customize Dashboard <GearIcon />
                </button>
              </div>
              <Outlet />
            </div>
          </main>
          <footer />
        </>
      ) : isSales ? (
        <Navigate to="/sales-and-invoicing/invoice-list" />
      ) : isStore ? (
        <Navigate to="/inventory-for-sales/inventory-entry" />
      ) : isHMB ? (
        <Navigate to="/hmb" />
      ) : isSupplyAndLogistics ? (
        <Navigate to="/supply-center" />
      ) : (
        <UnAuthorized />
      )}
    </>
  );
}
